import RequestActionTypeModel from "../models/RequestActionTypeModel";
import {IHttpRequestService} from "./HttpRequestService";

export interface IActionTypeService {
    GetAllForRequest(requestId: number, actionId: number):Promise<RequestActionTypeModel[]>;
    GetAll():Promise<RequestActionTypeModel[]>;
}

export class ActionTypeService implements IActionTypeService {
    constructor(private  httpRequestService: IHttpRequestService) {}

    public async GetAllForRequest(requestId: number, actionId: number):Promise<RequestActionTypeModel[]>{
        try{
            const { data } = await this.httpRequestService.Get(`/api/v1/RequestActionType/${requestId}/${actionId}/all`);
            return data as RequestActionTypeModel[];

        }catch(e) {
            this.httpRequestService.HandleError(e);
        }
        return [];
    }

    public async GetAll():Promise<RequestActionTypeModel[]>{
        try{
            const { data } = await this.httpRequestService.Get("/api/v1/RequestActionType/all");
            return data as RequestActionTypeModel[];

        }catch(e) {
            this.httpRequestService.HandleError(e);
        }
        return [];
    }
};