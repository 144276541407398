export const validateConditionalRequired = (obj: any, conditionExists: boolean|undefined, message: string) => {
  if(!conditionExists) return false;

  return obj == null ? message : true;
};

export const blockInvalidNumberChar = (e :any) => ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault();

export const blockInvalidWholeNumberChar = (e :any) => ['e', 'E', '+', '-', '.', ','].includes(e.key) && e.preventDefault();

export const blockLessGreaterThanChar = (e :any) => ['<', '>'].includes(e.key) && e.preventDefault();

export const namesValidationExp= /^[A-Za-zÀ-ÖØ-öø-ÿ0-9\.\-\'\s@.]+$/;

export const addressValidationExp=/^[A-Za-zÀ-ÖØ-öø-ÿ0-9\.\-\'\s\#\@\%\&\/@.]+$/;