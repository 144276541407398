import { RequestActionModel } from "../models/RequestActionModel";
import {IHttpRequestService} from "./HttpRequestService";

export interface IActionService {
    GetById(id: number): Promise<RequestActionModel | undefined>;
    Save(data:RequestActionModel): Promise<RequestActionModel | undefined>;
    Delete(id:number): Promise<boolean | undefined>;
}

export class ActionService implements IActionService {
    constructor(private  httpRequestService: IHttpRequestService) {}

    private prepare(data:RequestActionModel) :RequestActionModel
    {
        if(data?.actionDate)
            data.actionDate=new Date( data.actionDate);
        if(data?.bringForwardDate)
            data.bringForwardDate=new Date( data.bringForwardDate);

        return data;
    }

    public async GetById(id: number): Promise<RequestActionModel | undefined> {
        try {
            if (id > 0) {
                const {data} = await this.httpRequestService.Get(`/api/v1/RequestAction/${id}`);
                return data ? this.prepare(data) : data;
            }
            return new RequestActionModel();
        } catch (e) {
            this.httpRequestService.HandleError(e);
        }

        return undefined;
    }
     
    public async Save(data:RequestActionModel):Promise<RequestActionModel|undefined>{
        try{
            const response  =
                data.id>0 ?
                    await this.httpRequestService.Put(`/api/v1/RequestAction/Update`,data):
                    await this.httpRequestService.Post(`/api/v1/RequestAction/Add`,data);

            if(response)
                return response as RequestActionModel;
        }catch(e)
        {
            this.httpRequestService.HandleError(e);
        }
        return undefined;
    }

    public async Delete(id:number):Promise<boolean|undefined>{
        try{
            const response  =
                await this.httpRequestService.DeleteById(`/api/v1/RequestAction/${id}`);
            if(response)
                return response as boolean;
        }catch(e)
        {
            this.httpRequestService.HandleError(e);
        }
        return undefined;
    }
  };