import {createContext, useContext} from "react";
import {useHttpRequestService} from "./HttpRequestContext";
import {createActionTypeService} from "./Bootstrap";
import {IActionTypeService} from "../services/ActionTypeService";

const ActionTypeContext = createContext<IActionTypeService | null>(null);

export const ActionTypeProvider: React.FC<{children?: React.ReactNode}> =
    ({children}) => {
        const httpRequestService = useHttpRequestService();
        const actionTypeService = createActionTypeService(httpRequestService);
        return (
            <ActionTypeContext.Provider value={actionTypeService}>
                {children}
            </ActionTypeContext.Provider>
        );
}

export const useActionTypeService = () => {
    const actionTypeService = useContext(ActionTypeContext);
    if (!actionTypeService) {
        throw new Error('useActionTypeService must be used within a ActionTypeProvider');
    }
    return actionTypeService;
};