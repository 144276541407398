import React, {createContext, useContext} from "react";
import {IHttpRequestService} from "../services/HttpRequestService";
import {createHttpRequestService} from "./Bootstrap";
import {useApplicationContext} from "./ApplicationContext";

const HttpRequestContext = createContext<IHttpRequestService | null>(null);

export const HttpRequestProvider: React.FC<{children?: React.ReactNode}> =
    ({children}) => {
        const applicationContext = useApplicationContext();
        const httpRequestService = createHttpRequestService(applicationContext);
        return (
            <HttpRequestContext.Provider value={httpRequestService}>
                {children}
            </HttpRequestContext.Provider>
        );
};

export const useHttpRequestService = () => {
    const httpRequestService = useContext(HttpRequestContext);
    if (!httpRequestService) {
        throw new Error('useHttpRequestService must be used within a HttpRequestProvider');
    }
    return httpRequestService;
};