import {createContext, useContext} from "react";
import {ActionEditType} from "../types/ActionEditType";

const ActionEditContext = createContext<ActionEditType | undefined>(undefined);
export default ActionEditContext;

export const useActionEditContext = () => {
    const actionEditContext = useContext(ActionEditContext);
    if (!actionEditContext) {
        throw new Error('useActionEditContext must be used within a ActionEditContextProvider');
    }
    return actionEditContext;
};


