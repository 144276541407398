import { useEffect, useState } from "react";
import ApplicantModel from "../../../../models/ApplicantModel";
import {useApplicantService} from "../../../../contexts/ApplicantContext";

export const useApplicantData = (id: string | undefined) => {
    const [applicant, setApplicant] = useState<ApplicantModel | undefined>();
    const [loading, setLoading] = useState<boolean>(false);
    const applicantService = useApplicantService();

    useEffect(() => {
        if (id) {
            const fetchData = async () => {
                const data = await applicantService.GetById(parseInt(id));
                setApplicant(data);
            };

            setLoading(true);
            fetchData().then(() => {
                setLoading(false);
            });
        }
    }, [id, applicantService]);

    return { applicant, loading };
};