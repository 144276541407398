import { pageRouteUrls } from "./PageRouteUrls";
import SearchWrapperModel from "../models/SearchWrapperModel";
import { Breadcrumbs } from "./Constants";

type BreadcrumbTemplateKey =
    | 'request-new' | 'request-edit' | 'request-details'
    | 'action-new' | 'action-edit' | 'action-details'
    | 'applicant-new' | 'applicant-edit' | 'applicant-details'
    | 'manage-exceptions' | 'configure-exceptions' | 'configure-exceptions-new'
    | 'configure-notifications' | 'configure-notifications-edit'
    | 'search';

export interface BreadcrumbItem {
    title: string;
    link?: string;
    state?: any;
}

class BreadcrumbHelper {
    static createBreadcrumbItem(title: string, link?: string, previousPageUrls?: string[]): BreadcrumbItem {
        return {
            title,
            ...(link && { link }),
            ...(previousPageUrls && { state: { previousPageUrls } })
        };
    }

    static matchesRoute(previousPageUrls: string[], route: string): boolean {
        return previousPageUrls?.length > 0 && previousPageUrls[previousPageUrls.length - 1] === route;
    }

    static getLastUrl(previousPageUrls: string[]): string | undefined {
        return previousPageUrls?.length > 0 ? previousPageUrls[previousPageUrls.length - 1] : undefined;
    }

    static getBreadcrumbMap(): Record<string, BreadcrumbItem[]> {
        return Breadcrumbs;
    }

    static isValidId(id: string | number | undefined): boolean {
        return !!id && Number(id) > 0;
    }

    static getTemplateKey(type: string, isNew: boolean, isEdit: boolean): string {
        if (isNew) return `${type}-new`;
        return isEdit ? `${type}-edit` : `${type}-details`;
    }
}

export class BreadcrumbGenerator {
    private static readonly breadcrumbMap = BreadcrumbHelper.getBreadcrumbMap();

    static generateRequestBreadcrumb(
        previousPageUrls: string[],
        id: string | undefined,
        isEdit: boolean,
        searchWrapper?: SearchWrapperModel | null
    ): BreadcrumbItem[] {
        const requestId = id ? Number(id) : 0;
        const template = this.getRequestTemplate(id, isEdit);
        
        return this.generateBaseBreadcrumb(template, previousPageUrls, requestId, undefined)
            .map(item => ({
                ...item,
                ...(item.title === 'Search' && searchWrapper && {
                    state: { quSearchWrapper: searchWrapper }
                })
            }));
    }

    static generateActionBreadcrumb(
        previousPageUrls: string[],
        actionId: string | undefined,
        requestId: string | undefined,
        isEdit: boolean
    ): BreadcrumbItem[] {
        const requestIdNumber = requestId ? Number(requestId) : 0;
        const isEditRequest = BreadcrumbHelper.matchesRoute(previousPageUrls, pageRouteUrls.Request_Edit(requestIdNumber));
        const isNewAction = !BreadcrumbHelper.isValidId(actionId);
        const actionTemplateKey = BreadcrumbHelper.getTemplateKey('action', isNewAction, isEdit) as BreadcrumbTemplateKey;

        const template = [
            ...this.getRequestTemplate(requestId, isEditRequest),
            ...this.getConfigTemplate(actionTemplateKey)
        ];
        
        return this.generateBaseBreadcrumb(template, previousPageUrls, requestIdNumber, undefined);
    }

    static generateApplicantBreadcrumb(
        previousPageUrls: string[],
        requestId: number | undefined,
        applicantId: string | undefined,
        isEdit: boolean
    ): BreadcrumbItem[] {
        const previousUrlIsRequest = [
            pageRouteUrls.Request_Edit(requestId),
            pageRouteUrls.Request_Add(),
            pageRouteUrls.Request_Details(requestId)
        ].some(route => BreadcrumbHelper.matchesRoute(previousPageUrls, route));
        const isNewRequest = !BreadcrumbHelper.isValidId(requestId);
        const isEditRequest = !isNewRequest && BreadcrumbHelper.matchesRoute(previousPageUrls, pageRouteUrls.Request_Edit(requestId));
        const isNewApplicant = !BreadcrumbHelper.isValidId(applicantId);
        const applicantTemplateKey = BreadcrumbHelper.getTemplateKey('applicant', isNewApplicant, isEdit) as BreadcrumbTemplateKey;

        const template = [
            ...(previousUrlIsRequest ? this.getRequestTemplate(requestId?.toString(), isEditRequest) : []),
            ...this.getConfigTemplate(applicantTemplateKey)
        ];

        return this.generateBaseBreadcrumb(template, previousPageUrls, requestId, applicantId);
    }

    static generateManageExceptionsBreadcrumb(
        previousPageUrls: string[],
        requestId: string | undefined
    ): BreadcrumbItem[] {
        const requestIdNumber = requestId ? Number(requestId) : 0;
        const isEditRequest = BreadcrumbHelper.matchesRoute(previousPageUrls, pageRouteUrls.Request_Edit(requestId));
        
        return this.generateBaseBreadcrumb([
            ...this.getRequestTemplate(requestId, isEditRequest),
            ...this.getConfigTemplate('manage-exceptions')
        ], previousPageUrls, requestIdNumber, undefined);
    }

    static generateExceptionAddBreadcrumb(): BreadcrumbItem[] {
        return this.getConfigTemplate('configure-exceptions-new');
    }

    static generateExceptionViewBreadcrumb(): BreadcrumbItem[] {
        return this.getConfigTemplate('configure-exceptions');
    }

    static generateNotificationEditBreadcrumb(): BreadcrumbItem[] {
        return this.getConfigTemplate('configure-notifications-edit');
    }

    static generateNotificationViewBreadcrumb(): BreadcrumbItem[] {
        return this.getConfigTemplate('configure-notifications');
    }

    static generateSearchBreadcrumb(): BreadcrumbItem[] {
        return this.getConfigTemplate('search');
    }

    private static getRequestTemplate(id: string | undefined, isEdit: boolean): BreadcrumbItem[] {
        const isNewRequest = !BreadcrumbHelper.isValidId(id);
        const templateKey = BreadcrumbHelper.getTemplateKey('request', isNewRequest, isEdit) as BreadcrumbTemplateKey;
        return this.getConfigTemplate(templateKey);
    }

    private static generateBaseBreadcrumb(
        template: BreadcrumbItem[],
        previousPageUrls: string[],
        requestId: number | undefined,
        applicantId: string | undefined
    ): BreadcrumbItem[] {
        return template
            .filter(item => this.shouldIncludeSearchItem(item, previousPageUrls))
            .map((item, index, array) => ({
                ...item,
                ...this.getItemStateAndLink(item, index, array, {
                    previousPageUrls,
                    requestId,
                    applicantId
                })
            }));
    }

    private static shouldIncludeSearchItem(
        item: BreadcrumbItem,
        previousPageUrls: string[]
    ): boolean {
        return item.title !== 'Search' || 
            (previousPageUrls && previousPageUrls[0] === pageRouteUrls.Search());
    }

    private static getItemStateAndLink(
        item: BreadcrumbItem,
        index: number,
        array: BreadcrumbItem[],
        context: {
            previousPageUrls: string[],
            requestId?: number,
            applicantId?: string
        }
    ): Partial<BreadcrumbItem> {
        const { previousPageUrls, requestId, applicantId } = context;
        const isLastItem = index === array.length - 1;

        switch (item.title) {
            case 'Search':
                return previousPageUrls ? { state: { previousPageUrls } } : {};
            case 'New Request':
                return !isLastItem ? {
                    link: pageRouteUrls.Request_Add(),
                    state: { previousPageUrls }
                } : {};
            case 'Edit Request':
            case 'Edit Request Details':
                return !isLastItem ? {
                    link: pageRouteUrls.Request_Edit(requestId),
                    state: { previousPageUrls }
                } : {};
            case 'Request Details':
                return !isLastItem ? {
                    link: pageRouteUrls.Request_Details(requestId),
                    state: { previousPageUrls }
                } : {};
            case 'Applicant Details':
                return !isLastItem ? {
                    link: pageRouteUrls.Applicant_Details(applicantId),
                    state: { sourceRequestId: requestId, previousPageUrls }
                } : {};
            case 'Add Applicant':
                return { state: { previousPageUrls } };
            default:
                return {};
        }
    }

    private static getConfigTemplate(key: BreadcrumbTemplateKey): BreadcrumbItem[] {
        return [...this.breadcrumbMap[key]];
    }
}
