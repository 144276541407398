import React from "react";
import { Card, Form } from "react-bootstrap";
import ApplicantModel from "../../../../models/ApplicantModel";
import { ApplicantLabels } from "../../components/ApplicantLabels";

const AddressInfoDetails: React.FC<{ applicant: ApplicantModel }> = ({ applicant }) => (
    <Card className="mb-2 same-height">
        <Card.Header><strong>{ApplicantLabels.headers.addressInformation}</strong></Card.Header>
        <Card.Body>
            <div className="row mb-3">
                <div className="col-sm-3">
                    <Form.Label>{ApplicantLabels.labels.address1}</Form.Label>
                </div>
                <div className="col-sm-8">
                    <Form.Control plaintext readOnly value={applicant["address1"]} />
                </div>
            </div>
            <div className="row mb-3">
                <div className="col-sm-3">
                    <Form.Label>{ApplicantLabels.labels.address2}</Form.Label>
                </div>
                <div className="col-sm-8">
                    <Form.Control plaintext readOnly value={applicant["address2"]} />
                </div>
            </div>
            <div className="row mb-3">
                <div className="col-sm-3">
                    <Form.Label>{ApplicantLabels.labels.city}</Form.Label>
                </div>
                <div className="col-sm-8">
                    <Form.Control plaintext readOnly value={applicant["city"]} />
                </div>
            </div>
            <div className="row mb-3">
                <div className="col-sm-3">
                    <Form.Label>{ApplicantLabels.labels.province}</Form.Label>
                </div>
                <div className="col-sm-8">
                    <Form.Control plaintext readOnly value={applicant["province"]} />
                </div>
            </div>
            <div className="row mb-3">
                <div className="col-sm-3">
                    <Form.Label>{ApplicantLabels.labels.country}</Form.Label>
                </div>
                <div className="col-sm-8">
                    <Form.Control plaintext readOnly value={applicant["country"]} />
                </div>
            </div>
            <div className="row mb-3">
                <div className="col-sm-3">
                    <Form.Label>{ApplicantLabels.labels.postalCode}</Form.Label>
                </div>
                <div className="col-sm-8">
                    <Form.Control plaintext readOnly value={applicant["postalCode"]} />
                </div>
            </div>
        </Card.Body>
    </Card>
);

export default AddressInfoDetails;
