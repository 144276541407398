export const RequestLabels = {
    // Headers and Labels
    headers: {
        progress: "Request Progress",
        applicant: "Applicant",
        requestDetails: "Request Details",
        actionSummary: "Action Summary",
        exceptions: "Exceptions Applied",
        processingDetails: "Request Processing Details",
        responseDetails: "Request Response Details",
        completedDetails: "Completed Request Details"
    },

    labels: {
        // Processing Details
        requestSummary: "Request Summary",
        requestType: "Request Type",
        requestSource: "Request Source",
        documentLocation: "Document Location",

        // Response Details
        recordDisclosed: "Record Disclosed",
        disposition: "Disposition",
        releaseStatus: "Release Status",
        deliveryMethod: "Method of Delivery",

        // Completed Details
        numberOfPages: "Number of Pages",
        completedDate: "Completed Date",

        // Progress Fields
        fileNumber: "File Number",
        dateReceived: "Date Received",
        dueDate: "Due Date",
        requestLifecycle: "Request Lifecycle",
        requestStatus: "Request Status",

        // Applicant Fields
        firstName: "First Name",
        lastName: "Last Name",
        contactDetails: "Contact Details",

        // Search Applicant
        searchApplicant: "Search Applicant",
        moreDetails: "Click Here for more applicant details",

        // Exceptions Table
        exceptionCode: "Exception Code",
        exceptionDescription: "Description"
    },

    placeholders: {
        dateReceived: "Enter date received",
        dueDate: "Enter due date",
        documentLocation: "Enter Document Location",
        applicantName: "Enter Applicant Name",
        completedDate: "Enter Completed Date",
        select: "- Select -"
    },

    buttons: {
        save: "Save",
        cancel: "Cancel",
        edit: "Edit",
        newAction: "New Action",
        manageException: "Manage Exception",
        addApplicant: "Add Applicant"
    },

    messages: {
        daysSuffix: "days",
        noDataFound: "No match found. Please Add a new applicant.",
        noApplicantFound: "Please select an existing applicant or add a new applicant",
        noExceptions: "No Data found",
        dueDatePriorToDateReceived: "Due Date cannot be prior to the Date Received.",
        dateReceivedFuture: "Date Received cannot be in the future."
    }
};