import React from "react";
import { Card } from "react-bootstrap";
import { useApplicantEditContext } from "../../../../contexts/ApplicantEditContext";
import { AddressFormInput, AddressFormInputProps } from "./AddressFormInput";
import useAddressValidation from "../hooks/useAddressValidation";
import { ApplicantLabels } from "../../components/ApplicantLabels";

const AddressInfoEdit: React.FC = () => {
    const { applicantEditActions: { isCountryCanada } } = useApplicantEditContext();

    const { getAddressValidation, getPostalCodeValidation } = useAddressValidation(isCountryCanada);

    const formFields: AddressFormInputProps[] = [
        {
            label: ApplicantLabels.labels.address1,
            name: "address1",
            required: true,
            placeholder: ApplicantLabels.placeholders.address1,
            validation: getAddressValidation(ApplicantLabels.labels.address1, 60, true)
        },
        {
            label: ApplicantLabels.labels.address2,
            name: "address2",
            placeholder: ApplicantLabels.placeholders.address2,
            validation: getAddressValidation(ApplicantLabels.labels.address2, 60, false)
        },
        {
            label: ApplicantLabels.labels.city,
            name: "city",
            required: true,
            placeholder: ApplicantLabels.placeholders.city,
            validation: getAddressValidation(ApplicantLabels.labels.city, 50, true)
        },
        {
            label: ApplicantLabels.labels.province,
            name: "province",
            required: isCountryCanada(),
            placeholder: ApplicantLabels.placeholders.province,
            validation: getAddressValidation(ApplicantLabels.labels.province, 50, isCountryCanada())
        },
        {
            label: ApplicantLabels.labels.country,
            name: "country",
            required: true,
            placeholder: ApplicantLabels.placeholders.country,
            validation: getAddressValidation(ApplicantLabels.labels.country, 50, true)
        },
        {
            label: ApplicantLabels.labels.postalCode,
            name: "postalCode",
            required: isCountryCanada(),
            placeholder: ApplicantLabels.placeholders.postalCode,
            validation: getPostalCodeValidation()
        }
    ];

    return (
        <Card className="mb-2 same-height">
            <Card.Header><strong>{ApplicantLabels.headers.addressInformation}</strong></Card.Header>
            <Card.Body>
                {formFields.map((field) => (
                    <AddressFormInput key={field.name} {...field} />
                ))}
            </Card.Body>
        </Card>
    );
};

export default AddressInfoEdit;