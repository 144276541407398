import {createContext, useContext} from "react";
import {useHttpRequestService} from "./HttpRequestContext";
import {createActionService} from "./Bootstrap";
import {IActionService} from "../services/ActionService";

const ActionContext = createContext<IActionService | null>(null);

export const ActionProvider: React.FC<{children?: React.ReactNode}> =
    ({children}) => {
        const httpRequestService = useHttpRequestService();
        const actionService = createActionService(httpRequestService);
        return (
            <ActionContext.Provider value={actionService}>
                {children}
            </ActionContext.Provider>
        );
}

export const useActionService = () => {
    const actionService = useContext(ActionContext);
    if (!actionService) {
        throw new Error('useActionService must be used within a ActionProvider');
    }
    return actionService;
};