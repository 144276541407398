import {Col, Form} from "react-bootstrap";
import {ErrorMessage} from "@hookform/error-message";
import {Controller} from "react-hook-form";
import {getDatePickerProps, validateDate} from "../../../../helper/DateUtilities";
import dayjs from "dayjs";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import React from "react";
import {useApplicantEditContext} from "../../../../contexts/ApplicantEditContext";
import {namesValidationExp} from "../../../../helper/ValidationUtilities";
import { ApplicantLabels } from "../../components/ApplicantLabels";

const ApplicantInfoEdit = () => {
    const {
        applicantEditFormState: {control, register, formState, trigger},
    } = useApplicantEditContext();

    const renderFormField = (
        id: string,
        label: string,
        fieldName: "firstName" | "lastName" | "dateOfBirth",
        validation: object,
        customInput?: React.ReactNode
    ) => (
        <Form.Group as={Col} controlId={`form${id}`}>
            <Form.Label>{label}</Form.Label>
            {customInput || (
                <Form.Control
                    type="text"
                    autoFocus={fieldName === "firstName"}
                    {...register(fieldName, validation)}
                    className="required"
                    placeholder={`Enter ${label}`}
                />
            )}
            <ErrorMessage
                errors={formState.errors}
                name={fieldName}
                render={({ message }) => <span className="error-message">{message}</span>}
            />
        </Form.Group>
    );

    const nameValidation = (fieldName: string) => ({
        pattern: {
            value: namesValidationExp,
            message: `Please enter a valid ${fieldName}.`
        },
        required: `${fieldName} is required.`,
        maxLength: { value: 35, message: `${fieldName} cannot exceed 35 characters.` }
    });

    const datePickerField = (
        <Controller
            control={control}
            name='dateOfBirth'
            rules={{ 
                validate: (date) => validateDate(
                    date,
                    "Date of Birth",
                    undefined,
                    undefined,
                    dayjs(new Date()),
                    'Date of Birth cannot be a future date.'
                )
            }}
            render={({ field }) => (
                <DatePicker
                    {...getDatePickerProps(() => trigger(), 'dateOfBirth')}   
                    value={field.value ? dayjs(field.value) : null}
                    slotProps={{
                        textField: { 
                            placeholder: "Enter Date of Birth",
                            className: "form-control" 
                        }
                    }}
                    onChange={(date) => field.onChange(date)}
                />
            )}
        />
    );

    return (
        <>
            {renderFormField("firstName", ApplicantLabels.labels.firstName, "firstName", nameValidation("First Name"))}
            {renderFormField("lastName", ApplicantLabels.labels.lastName, "lastName", nameValidation("Last Name"))}
            {renderFormField("dateOfBirth", ApplicantLabels.labels.dateOfBirth, "dateOfBirth", {}, datePickerField)}
        </>
    );
};

export default ApplicantInfoEdit;