import { Button, Card, Col, Container, Form, Row, Spinner, Table } from "react-bootstrap";
import Layout from "../../component/Layout"
import {  Link, useNavigate } from "react-router-dom";  
import {useContext, useEffect, useState} from "react";
import { useForm, SubmitHandler, Controller } from "react-hook-form"; 
import { makeStyles, styled } from '@mui/material/styles'; 
import Switch, { SwitchProps } from '@mui/material/Switch'; 
import { ErrorMessage } from "@hookform/error-message";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import React from "react";
import { NotificationConfigModel } from "../../models/NotificationConfigModel";
import { pageRouteUrls } from "../../helper/PageRouteUrls"; 
import { EmailExpValidator } from "../../helper/Constants";
import {blockInvalidNumberChar} from "../../helper/ValidationUtilities";
import {useNotificationConfigurationService} from "../../contexts/NotificationConfigurationContext";
import {BreadcrumbGenerator} from "../../helper/BreadcrumbUtilities";

export interface Props {}
 
const EditNotificationsConfig: React.FC<Props> = props => {   
    const [loading, setLoading] =useState<boolean>(false);
    const [configData,setConfigData]=useState<NotificationConfigModel>(new NotificationConfigModel()); 
    const [btnDisable,setBtnDisable]=useState<boolean>(false);  
    const navigate = useNavigate();
    const { control, register, handleSubmit, formState: { errors }, reset,setValue } = useForm<NotificationConfigModel>({ defaultValues: new NotificationConfigModel() });
    const notificationConfigurationService = useNotificationConfigurationService();

    useEffect(() => { 
          fetchData();  
    }, []);
    const fetchData = async () => { 
        setBtnDisable(true);
        setLoading(true);
        
        const data=await notificationConfigurationService.GetConfig();
        setConfigData(data);         
        reset(data);  

        setLoading(false);
        setBtnDisable(false);
    } 

    const onSubmit: SubmitHandler<NotificationConfigModel> = async (data) => {
        setBtnDisable(true);   
        setLoading(true);  

        const responceSave=await notificationConfigurationService.Save(data);
              
        reset(data);
        if (responceSave) { 
            setConfigData(responceSave);   
            navigate(pageRouteUrls.NotificationsConfig_View());
        } 
        setLoading(false);
        setBtnDisable(false);
    }
   
    const IOSSwitch = styled((props: SwitchProps) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
    ))(({ theme }) => ({
        width: 42,
        height: 26,
        padding: 0,
        '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
            backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
            opacity: 1,
            border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
            opacity: 0.5,
            },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            color:
            theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[600],
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
        },
        },
        '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 22,
        height: 22,
        },
        '& .MuiSwitch-track': {
        borderRadius: 26 / 2,
        backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 500,
        }),
        },
    }));
        

  // BREADCRUMB FOR LAYOUT
    const breadcrumb = BreadcrumbGenerator.generateNotificationEditBreadcrumb();
    const validateEmails = (value:string|null) => {
        if(value)
        {
            const emails = value.split(';').map(email => email.trim());
        
            for (const email of emails) {
            if (!email.match(EmailExpValidator)) {
                return 'Please enter a valid email address';
            }
            if (email.length > 320) {
                return 'Email address is too long';
            }
            }
        }
        return true;
      };

    return (
      
      <Layout breadcrumList={breadcrumb} >
        <Form onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
          <LocalizationProvider dateAdapter={AdapterDayjs}>

            <Card  className="mb-3">
                <Card.Header>
                    <Row>
                        <Col  className="fs-5">
                        <strong className="mx-2 colorDark">Edit Configure Notifications</strong>
                        </Col>  
                        
                        <Col className="textAlign-end"> 
                            <Button variant="primary" type="submit" disabled={btnDisable}>Save</Button>
                            <Link to={pageRouteUrls.NotificationsConfig_View()} className="btn btn-dark">Cancel</Link> 
                        </Col>
                    </Row>
                </Card.Header>
            <Card.Body>
                {loading ? (
                        <Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                    ) : (
                    <Container>
                        <Row className=" mb-3">
                            <Col sm={4} lg={4} className="">
                                <Form.Label className="pt-2">Turn Email Notifications On/Off</Form.Label> 
                            </Col>
                            <Col sm={8} lg={8}>
                                <Controller
                                    control={control}
                                    name='sendNotifications' 
                                    render={({ field }) => (
                                        <IOSSwitch sx={{ m: 1 }}  
                                            checked={field.value?field.value:false} 
                                            onChange={(e: { target: { checked: any; }; }) =>field.onChange(e.target.checked) }
                                        /> 
                                    )}
                                /> 
                            
                            
                            </Col>
                        </Row> 
                        <Row className=" mb-3 ">
                            <Col sm={4} lg={4} className="">
                                <Form.Label className="remove-margin-bottom">Recipient Email Addresses (separated by ;)</Form.Label>  
                            </Col>
                            <Col sm={8} lg={8}>
                                <Form.Control type="text" {...register("emailAddress",
                                            {
                                                validate: (a)=> validateEmails(a)
                                            })} 
                                                    placeholder="Enter Email Address(s)" />

                                <ErrorMessage errors={errors} name="emailAddress" render={({ message }) => <span className="error-message" >{message}</span>}/> 
                            </Col>
                        </Row> 
                        <Row className=" mb-3">
                            <Col sm={4} lg={4} className="">
                                <Form.Label>Due Date Reminder Lead Time (in days)</Form.Label> 
                            </Col>
                            <Col sm={8} lg={8}>
                                <Form.Control type="number"
                                    {...register("dueDateNotificationDays", 
                                        { 
                                            max: { value: 30, message: "Please enter a value between 1 - 30 for the number of days." },
                                            min: { value: 1, message: "Please enter a value between 1 - 30 for the number of days." },
                                            valueAsNumber: true,
                                        })}
                                    onKeyDown={blockInvalidNumberChar}
                                    placeholder="Enter Number of Days" />
                                <ErrorMessage errors={errors} name="dueDateNotificationDays" render={({ message }) => <span className="error-message" >{message}</span>} />
                            </Col>
                        </Row> 
                    </Container>  
                       
                    )}

 

              </Card.Body>
            </Card>
          </LocalizationProvider>
        </Form>  
      </Layout>
    )
}
export default EditNotificationsConfig
 