import React from "react";
import {Col, Form, Row} from "react-bootstrap";
import {getEnumLabel, PaymentMethodEnum} from "../../../../helper/Enums";
import {ErrorMessage} from "@hookform/error-message";
import {blockInvalidNumberChar} from "../../../../helper/ValidationUtilities";
import {useActionEditContext} from "../../../../contexts/ActionEditContext";

const PaymentReceivedEdit = () => {
    const actionEditContext = useActionEditContext();

    const {
        actionEditState: { actionData },
        actionEditStateActions: {setActionData},
        actionEditFormState: {register, formState: { errors }}
    } = actionEditContext;

    const onPaymentMethodChange = (selectedValue: string) => {
        let payment = {
            ...actionData,
            ['payment.paymentMethodId']: Number(selectedValue)
        };
        setActionData(payment);
    }

    return (
        <>
            <Row className="mb-3">
                <Form.Group as={Col} lg="6">
                    <Form.Label>Payment Method</Form.Label>

                    <div>
                        <select {...register("payment.paymentMethodId", {required: 'Payment Method is required.'})}
                                className="required form-select"
                                onChange={(e) => onPaymentMethodChange(e.target.value)}>
                            <option key="SearchCriteria-1" value="">- Select -
                            </option>
                            {Object.keys(PaymentMethodEnum).filter((v) => !isNaN(Number(v))).map((key, index) =>
                                (
                                    <option key={"SearchCriteria" + key}
                                            value={Number(key)}>
                                        {getEnumLabel(PaymentMethodEnum[Number(key)])}
                                    </option>
                                ))}
                        </select>
                        <ErrorMessage errors={errors} name="payment.paymentMethodId"
                                      render={({message}) => <span
                                          className="error-message">{message}</span>}/>
                    </div>
                </Form.Group>

                <Form.Group as={Col} lg="6">
                    <Form.Label>Receipt Number</Form.Label>
                    <Form.Control type="text" {...register("payment.receiptNumber", {
                        required: 'The Receipt Number is required.',
                        maxLength: {value: 255, message: 'Please enter a valid Receipt Number'}
                    })}
                                  placeholder="Enter the Receipt Number"
                                  className="required"/>
                    <ErrorMessage errors={errors} name="payment.receiptNumber"
                                  render={({message}) => <span
                                      className="error-message">{message}</span>}/>
                </Form.Group>

            </Row>
            <Row className="mb-3">
                <Form.Group as={Col} lg="6">
                    <Form.Label>Payment Amount</Form.Label>
                    <Form.Control
                        type="number" {...register("payment.amount", {
                        required: 'Amount is required.',
                        min: {value: 0, message: 'Please enter a valid Payment Amount.'},
                        max: {value: 99999, message: 'Please enter a valid Payment Amount.'}
                    })}
                        onKeyDown={blockInvalidNumberChar}
                        placeholder="Enter the amount in CAD"
                        className="required"/>
                    <ErrorMessage errors={errors} name="payment.amount"
                                  render={({message}) => <span
                                      className="error-message">{message}</span>}/>
                </Form.Group>
            </Row>
        </>
    );
};

export default PaymentReceivedEdit;
