import { Checkbox } from "@mui/material";
import { Table } from "react-bootstrap";
import { Col, Row } from "react-bootstrap";
import ExceptionTypeModel from "../../../../models/ExceptionTypeModel";
import { ConfigureExceptionsLabels } from "../../components/ConfigureExceptionsLabels";

interface ExceptionViewProps {
    exceptions: ExceptionTypeModel[];
    onCheckboxChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const ExceptionView: React.FC<ExceptionViewProps> = ({
    exceptions,
    onCheckboxChange
}) => {
    return (
        <Row className="mb-3">
            <Col>
                <Table className="ActiveHomeTable HomeTable" bordered hover size="sm">
                    <thead>
                        <tr className="bg-primary">
                            <th>{ConfigureExceptionsLabels.headers.exceptionTableHeaders.selectUnselect}</th>
                            <th>{ConfigureExceptionsLabels.headers.exceptionTableHeaders.exceptionCode}</th>
                            <th>{ConfigureExceptionsLabels.headers.exceptionTableHeaders.description}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {exceptions.length > 0 ? (
                            exceptions.map((exception, index) => (
                                <tr key={`exceptionRow-${index}`}>
                                    <td>
                                        <Checkbox
                                            onChange={onCheckboxChange}
                                            value={exception.id}
                                        />
                                    </td>
                                    <td>{exception.code}</td>
                                    <td className="tableExceptionDescriptionCell">
                                        {exception.name}
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan={3}>{ConfigureExceptionsLabels.messages.noDataFound}</td>
                            </tr>
                        )}
                    </tbody>
                </Table>
            </Col>
        </Row>
    );
};