import {SignedOutRequestStatus} from "../helper/Constants";
import {AxiosError} from "axios";
import Swal from "sweetalert2";
import axiosInstance from "../helper/AxiosUtilities";
import authUtility from "../helper/AuthUtilities";
import {ApplicationContextType} from "../types/ApplicationContextType";

export interface IHttpRequestService {
    GetFile(apiUrl:string):Promise<any>;
    Get(apiUrl:string):Promise<any>;
    Post(apiUrl:string,data:any):Promise<any>;
    Put(apiUrl:string,data:any):Promise<any>;
    Delete(apiUrl:string,data:any):Promise<any>;
    DeleteById(apiUrl:string):Promise<any>;
    HandleError(e:any):void;
}

export class HttpRequestService implements IHttpRequestService {
    constructor(private applicationContext: ApplicationContextType) {}

    public async GetFile(apiUrl:string ){
        return await axiosInstance.get(apiUrl, {responseType: 'blob'});
    }

    public async Get(apiUrl:string ){
        return await axiosInstance.get(apiUrl );
    }

    public async Post(apiUrl:string,data:any ){
        const response = await axiosInstance.post(apiUrl,  JSON.stringify(data)  );
        return response.data;
    }

    public async Put(apiUrl:string,data:any ){
        const response = await axiosInstance.put(apiUrl,  JSON.stringify(data) );
        return response.data;
    }

    public async Delete(apiUrl:string,data:any ){
        const response = await axiosInstance.delete(apiUrl,
            {
                data: JSON.stringify(data)
            }
        );
        return response.data;
    }

    public async DeleteById(apiUrl:string ){
        const response = await axiosInstance.delete(apiUrl);
        return response.data;
    }

    public HandleError(e:any)
    {
        if(e === SignedOutRequestStatus) return;

        console.log(e);

        const err = e as AxiosError;
        let errHtml='';
        let title=this.GetErrorTitle(err.request?.status);

        switch(err.request?.status)
        {
            case 401:
             return authUtility.handleLogin();
            case 404:
                errHtml="Requested record was not found.<br>";
                Swal.fire({html: errHtml,icon: 'warning'}).then(()=>{ window.location.href = "/"; });
                break;
            case 400:
                errHtml=err.request && err.request.response?err.request.response : `Something went wrong!<br><small>${err.message}</small>`;
                Swal.fire({title: title,html: errHtml,icon: 'warning'});
                break;
            case 422:
                this.FireValidationAlert(e, title);
                break;
            default:
                errHtml=`Something went wrong!<br><small>${err.message}</small>`;
                Swal.fire({title: title,html: errHtml,icon: 'error'});
        }
    }

    private GetErrorTitle(errorCode:number):string
    {
        switch(errorCode)
        {
            case 400: return 'Bad Request';
            case 401: return 'Unauthorized';
            case 402: return 'Payment Required';
            case 403: return 'Forbidden';
            case 404: return 'Not Found';
            case 405: return 'Method Not Allowed';
            case 406: return 'Not Acceptable';
            case 407: return 'Proxy Authentication Required';
            case 408: return 'Request Timeout';
            case 409: return 'Conflict';
            case 410: return 'Gone';
            case 411: return 'Length Required';
            case 412: return 'Precondition Failed';
            case 413: return 'Payload Too Large';
            case 414: return 'URI Too Long';
            case 415: return 'Unsupported Media Type';
            case 416: return 'Range Not Satisfiable';
            case 417: return 'Expectation Failed';
            case 421: return 'Misdirected Request';
            case 422: return 'Unprocessable Entity';
            case 423: return 'Locked (WebDAV; RFC 4918)';
            case 424: return 'Failed Dependency (WebDAV; RFC 4918)';
            case 425: return 'Too Early (RFC 8470)';
            case 426: return 'Upgrade Required';
            case 428: return 'Precondition Required (RFC 6585)';
            case 429: return 'Too Many Requests (RFC 6585)';
            case 431: return 'Request Header Fields Too Large (RFC 6585)';
            case 451: return 'Unavailable For Legal Reasons (RFC 7725)';
            case 500: return 'Internal Server Error';
            case 501: return 'Not Implemented';
            case 502: return 'Bad Gateway';
            case 503: return 'Service Unavailable';
            case 504: return 'Gateway Timeout';
            case 505: return 'HTTP Version Not Supported';
            case 506: return 'Variant Also Negotiates (RFC 2295)';
            case 507: return 'Insufficient Storage (WebDAV; RFC 4918)';
            case 508: return 'Loop Detected (WebDAV; RFC 5842)';
            case 510: return 'Not Extended (RFC 2774)';
            case 511: return 'Network Authentication Required (RFC 6585)';
            default:
                return 'Application Error';
        }
    }

    private FireValidationAlert = (e:any, title: string) => {
        if(!this.applicationContext){
            const errHtml=`Validation Error!<br><small>${e.message}</small>`;
            Swal.fire({title: title,html: errHtml,icon: 'error'}).then();
            return;
        }

        const { setValidationMessage } = this.applicationContext!;
        setValidationMessage(e.response.data);
    }
}