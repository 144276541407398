import React, {useEffect} from "react";
import Layout from "../../../component/Layout";
import {pageRouteUrls} from "../../../helper/PageRouteUrls";
import {Link} from "react-router-dom";
import {Row, Col, Card, Form, Button, Container} from "react-bootstrap";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import PaymentReceivedEdit from "./components/PaymentReceivedEdit";
import SearchSentEdit from "./components/SearchSentEdit";
import SearchReceivedEdit from "./components/SearchReceivedEdit";
import PaymentRequestedEdit from "./components/PaymentRequestedEdit";
import ExtensionRequestedEdit from "./components/ExtensionRequestedEdit";
import ResponseReceivedEdit from "./components/ResponseReceivedEdit";
import {LoadingSpinner} from "../../../component/LoadingSpinner";
import useActionEdit from "./hooks/useActionEdit";
import BringForwardDueDateEdit from "./components/BringForwardDueDateEdit";
import ActionDateEdit from "./components/ActionDateEdit";
import ActionTypeEdit from "./components/ActionTypeEdit";
import ActionDelete from "./components/ActionDelete";
import ActionEditContext from "../../../contexts/ActionEditContext";
import useSubmitActionEdit from "./hooks/submitActionEdit";
import {BreadcrumbGenerator} from "../../../helper/BreadcrumbUtilities";
export interface Props {
}

const ActionEdit: React.FC<Props> = () => {
    const actionEditContext= useActionEdit();
    const {
        actionEditState: {id, requestId, btnDisable, previousPageUrls, allActionTypes, actionData, actionType, loading},
        actionEditFormState,
        actionEditActions: {loadAllActionTypes, loadAction, loadRequest}
    } = actionEditContext;

    const {onSubmit} = useSubmitActionEdit(actionEditContext);

    const breadcrumb = BreadcrumbGenerator.generateActionBreadcrumb(previousPageUrls, id, requestId, true);
    const cardTitle = id ? "Edit Action Details" : "New Action";

    useEffect(() => {
        loadAllActionTypes();
    }, [id, requestId]);

    useEffect(() => {
        loadAction();
    }, [id, allActionTypes]);

    useEffect(() => {
        loadRequest();
    },  [requestId, actionData, actionType]);

    return (
        <Layout breadcrumList={breadcrumb}>
            {loading ? (
                <LoadingSpinner/>
            ) : (
                <ActionEditContext.Provider value={actionEditContext}>
                    <Form onSubmit={actionEditFormState.handleSubmit(onSubmit)} noValidate autoComplete="off">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <Card>
                                <Card.Header>
                                    <Row>
                                        <Col className="fs-5">
                                            <strong className="mx-2 colorDark">{cardTitle}</strong>
                                        </Col>
                                        <Col className="textAlign-end">
                                            <ActionDelete/>
                                            <Button variant="primary" type="submit" disabled={btnDisable}>Save</Button>
                                            <Link to={id ? pageRouteUrls.Action_Details(requestId, id) :
                                                (previousPageUrls && previousPageUrls.length>0 && previousPageUrls[previousPageUrls.length - 1])? previousPageUrls[previousPageUrls.length - 1]: pageRouteUrls.Request_Details(requestId)
                                            } state={{previousPageUrls: previousPageUrls}}
                                                  className="btn btn-dark">Cancel</Link>
                                        </Col>
                                    </Row>
                                </Card.Header>
                                <Card.Body>
                                    <Container>
                                        <Row className="mb-3">
                                        </Row>
                                        <Row className="mb-3">
                                            <Form.Group as={Col} lg="6">
                                                <Form.Label>File Number</Form.Label>
                                                <span className="ps-4">{actionData.requestFileNumber} </span>
                                            </Form.Group>
                                            <Form.Group as={Col} lg="6">
                                            </Form.Group>
                                        </Row>
                                        <Row className="mb-3">
                                            <ActionTypeEdit/>
                                        </Row>
                                        <Row className="mb-3">
                                            <ActionDateEdit/>
                                            <BringForwardDueDateEdit/>
                                        </Row>
                                        {
                                            actionType?.requiresAmount ?
                                                <PaymentRequestedEdit/>
                                                : actionType?.requiresReceipt ?
                                                    <PaymentReceivedEdit/>
                                                    : actionType?.requiresDepartmentToSearch ?
                                                        <SearchSentEdit/>
                                                        : actionType?.requiresDepartmentReceivedRecordsFrom ?
                                                            <SearchReceivedEdit/>
                                                            : actionType?.requiresExtension ?
                                                                <ExtensionRequestedEdit/>
                                                                : actionType?.requiresResponseReceived ?
                                                                    <ResponseReceivedEdit/>
                                                                    : <></>
                                        }

                                    </Container>
                                </Card.Body>
                            </Card>
                        </LocalizationProvider>
                    </Form>
                </ActionEditContext.Provider>
            )}
        </Layout>

    )
}
export default ActionEdit