import {Col, Form, Row} from "react-bootstrap";
import {Controller, useFormContext} from "react-hook-form";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {ErrorMessage} from "@hookform/error-message";
import React, {useContext} from "react";
import ActionEditContext, {useActionEditContext} from "../../../../contexts/ActionEditContext";
import dayjs, {Dayjs} from "dayjs";
import {validateConditionalRequired} from "../../../../helper/ValidationUtilities";
import {validateDate} from "../../../../helper/DateUtilities";

const BringForwardDueDateEdit = () => {
    const actionEditContext = useActionEditContext();

    const {
        actionEditState: {
            actionData,
            actionType,
            minBringForwardDate,
            maxBringForwardDate
        },
        actionEditFormState: {control, formState: { errors }, setValue }
    } = actionEditContext;

    const validateBringForwardDate = (date: Date|null, minDate: Dayjs|null, maxDate: Dayjs|null) => {
        const fieldName = "Bring Forward Due Date";
        let isRequiredOrError = validateConditionalRequired(
            date
            , actionType?.requiresBringForwardDate
            , `The ${fieldName} is required.`
        );

        return typeof (isRequiredOrError) == "boolean"
            ? validateDate(date
                , fieldName
                , minDate
                , `Invalid Date. Please enter a valid ${fieldName}.`
                , maxDate
                , `Invalid Date. Please enter a valid ${fieldName}.`
            )
            : isRequiredOrError;
    };

    const setBringForwardDate = (date: Date|null) => {
        if(!date && actionType?.requiresBringForwardDate){
            let bfDate = dayjs(actionData.actionDate).add(actionType?.bringForwardMaxDaysForward,'day');
            setValue("bringForwardDate", bfDate.toDate());
            return bfDate;
        }

        return date ? dayjs(date) : null;
    };

    return (
        <Form.Group as={Col} lg="6">
            <Form.Label>Bring Forward Due Date</Form.Label>
            <Controller
                control={control}
                name='bringForwardDate'
                rules={{
                    validate: (date) => validateBringForwardDate(date, minBringForwardDate, maxBringForwardDate)
                }}
                render={({field}) => (
                    <DatePicker
                        views={['year', 'month', 'day']}
                        onChange={(date) => field.onChange(date)}
                        value={setBringForwardDate(field.value)}
                        minDate={ minBringForwardDate == null ? undefined : minBringForwardDate }
                        maxDate={ maxBringForwardDate == null ? undefined : maxBringForwardDate }
                        slotProps={{
                            textField: {
                                placeholder: "Enter the bring forward due date",
                                className: actionType?.requiresBringForwardDate ? "form-control requiredDate" : "form-control"
                            }
                        }}
                    />
                )}
            />
            <ErrorMessage errors={errors} name="bringForwardDate"
                          render={({message}) => <span
                              className="error-message">{message}</span>}/>
        </Form.Group>
    );
};

export default BringForwardDueDateEdit;
