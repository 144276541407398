import { NotificationConfigModel } from "../models/NotificationConfigModel";
import {IHttpRequestService} from "./HttpRequestService";

export interface INotificationConfigurationService {
    GetConfig(): Promise<NotificationConfigModel>;
    Save(data: NotificationConfigModel): Promise<NotificationConfigModel | undefined>;
}

export class NotificationConfigurationService implements INotificationConfigurationService {
    constructor(private httpRequestService: IHttpRequestService) {}

    public async GetConfig():Promise<NotificationConfigModel>{
        try{   
            const { data } = await this.httpRequestService.Get(`/api/v1/NotificationConfiguration`);
            return data as NotificationConfigModel;                
        }catch(e)
        {
            this.httpRequestService.HandleError(e);
        }
        return new NotificationConfigModel();
    } 
    public async Save(data:NotificationConfigModel):Promise<NotificationConfigModel|undefined>{
        try{
            
            const response  =
            data.id>0 ?
                await this.httpRequestService.Put(`/api/v1/NotificationConfiguration/Update`,data):
                await this.httpRequestService.Post(`/api/v1/NotificationConfiguration/Add`,data);
            
            if(response)
                return response as NotificationConfigModel;
        }catch(e)
        {
            this.httpRequestService.HandleError(e);
        }
        return undefined;
    }
     
}