import React from "react";
import { Row, Col, Card, Form, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Stepper, Step, StepLabel, ThemeProvider } from "@mui/material";
import { calculateLifecycle, calculateStepNumber, formatDateAs } from "../../../../helper/DateUtilities";
import { DateFormat } from "../../../../helper/Constants";
import { pageRouteUrls } from "../../../../helper/PageRouteUrls";
import { storageMng } from "../../../../helper/StorageMng";
import StepperTheme from "../../../../styles/themes/StepperTheme";
import RequestModel from "../../../../models/RequestModel";
import { RequestLabels } from "../../components/RequestLabels";

interface RequestProgressProps {
    request: RequestModel;
    steps: string[];
    id: string | undefined;
    previousPageUrls: string[];
    quSearchWrapper: any;
}

const RequestProgress: React.FC<RequestProgressProps> = ({ 
    request, 
    steps, 
    id, 
    previousPageUrls, 
    quSearchWrapper 
}) => {
    const userInfo = storageMng.getUserProfile();

    const stepNumber = (request: RequestModel): number => {
        return calculateStepNumber(request?.completedDate, request?.dateReceived);
    };

    return (
        <Card className="mb-3">
            <Card.Header>
                <Row>
                    <Col className="fs-5">
                        <strong className="mx-2 colorDark">{RequestLabels.headers.progress}</strong>
                    </Col>
                    <Col className="textAlign-end">
                        {userInfo?.isAdministrator && (
                            <Link 
                                to={pageRouteUrls.Request_Edit(id)} 
                                state={{ previousPageUrls }} 
                                className="btn btn-primary"
                            >
                                {RequestLabels.buttons.edit}
                            </Link>
                        )}
                        <Link 
                            to={previousPageUrls[0] === pageRouteUrls.Search() ? pageRouteUrls.Search() : pageRouteUrls.Home()}
                            state={{ quSearchWrapper }}
                            className="btn btn-dark"
                        >
                            {RequestLabels.buttons.cancel}
                        </Link>
                    </Col>
                </Row>
            </Card.Header>
            <Card.Body>
                <Container>
                    <Row>
                        <Col lg="12">
                            <ThemeProvider theme={StepperTheme}>
                                <Stepper activeStep={stepNumber(request)}>
                                    {steps.map((label) => (
                                        <Step key={`step-${label}`}>
                                            <StepLabel>{label}</StepLabel>
                                        </Step>
                                    ))}
                                </Stepper>
                            </ThemeProvider>
                        </Col>
                    </Row>
                    <Row className="mb-3" />
                    <Row className="mb-3">
                        <Form.Group as={Col}>
                            <Form.Label>{RequestLabels.labels.fileNumber}</Form.Label>
                            <Form.Control plaintext readOnly value={request.fileNumber} />
                        </Form.Group>

                        <Form.Group as={Col}>
                            <Form.Label>{RequestLabels.labels.dateReceived}</Form.Label>
                            <Form.Control plaintext readOnly value={formatDateAs(request.dateReceived, DateFormat)} />
                        </Form.Group>

                        <Form.Group as={Col}>
                            <Form.Label>{RequestLabels.labels.dueDate}</Form.Label>
                            {request.dueDate && (
                                <Form.Control plaintext readOnly value={formatDateAs(request.dueDate, DateFormat)} />
                            )}
                        </Form.Group>

                        <Form.Group as={Col}>
                            <Form.Label>{RequestLabels.labels.requestLifecycle}</Form.Label>
                            <Form.Control 
                                plaintext 
                                readOnly 
                                value={`${calculateLifecycle(request.dateReceived, request.completedDate)} ${RequestLabels.messages.daysSuffix}`} 
                            />
                        </Form.Group>

                        <Form.Group as={Col}>
                            <Form.Label>{RequestLabels.labels.requestStatus}</Form.Label>
                            <Form.Control plaintext readOnly value={request.requestStatus} />
                        </Form.Group>
                    </Row>
                </Container>
            </Card.Body>
        </Card>
    );
};

export default RequestProgress;