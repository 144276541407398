import React, {createContext, Dispatch, useContext, useState} from "react";
import {ApplicationContextType} from "../types/ApplicationContextType";

const ApplicationContext = createContext<ApplicationContextType | undefined>(undefined);

export const ApplicationContextProvider: React.FC<{children?: React.ReactNode}> =
    ({children}) => {
        const [validationMessage, setValidationMessage] = useState<object | null>(null);

        return (
            <ApplicationContext.Provider value={{validationMessage, setValidationMessage}}>
                {children}
            </ApplicationContext.Provider>
        );
    }

export const useApplicationContext = () => {
    const applicationContext = useContext(ApplicationContext);
    if (!applicationContext) {
        throw new Error('useApplicationContext must be used within a ApplicationContextProvider');
    }
    return applicationContext;
};



