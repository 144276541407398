import { Row, Container } from "react-bootstrap";
import ValidationAlert from "../../../../component/ValidationAlert";
import { useExceptionAddContext } from "../../../../contexts/ExceptionAddContext";
import { ConfigureExceptionsLabels } from "../../components/ConfigureExceptionsLabels";
import { FormField } from "./ExceptionFormInput";

export const ExceptionAdd: React.FC = () => {
    const {
        exceptionAddState: { errMsg },
        exceptionAddFormState: { register, formState },
    } = useExceptionAddContext();

    return (
        <Container>
            {errMsg && (
                <Row className="mb-3">
                    <ValidationAlert message={errMsg} />
                </Row>
            )}
            <FormField
                name="code"
                label={ConfigureExceptionsLabels.labels.exceptionCode}
                register={register}
                rules={{
                    required: ConfigureExceptionsLabels.validation.codeRequired,
                    maxLength: {
                        value: 50,
                        message: ConfigureExceptionsLabels.validation.codeMaxLength
                    }
                }}
                errors={formState.errors}
                placeholder={ConfigureExceptionsLabels.placeholders.exceptionCode}
            />
            <FormField
                name="name"
                label={ConfigureExceptionsLabels.labels.description}
                register={register}
                rules={{
                    required: ConfigureExceptionsLabels.validation.descriptionRequired,
                    maxLength: {
                        value: 128,
                        message: ConfigureExceptionsLabels.validation.descriptionMaxLength
                    }
                }}
                errors={formState.errors}
                placeholder={ConfigureExceptionsLabels.placeholders.description}
            />
        </Container>
    );
};  