import React from 'react';
import { Table } from 'react-bootstrap';
import { Checkbox } from '@mui/material';
import ExceptionTypeModel from '../../../models/ExceptionTypeModel';
import { ExceptionsLabels } from './ExceptionsLabels';

interface ExceptionsTableProps {
    allExceptionTypes: ExceptionTypeModel[];
    selectedExpIds: number[];
    onCheckboxChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const ExceptionsTable: React.FC<ExceptionsTableProps> = ({
    allExceptionTypes,
    selectedExpIds,
    onCheckboxChange
}) => {
    return (
        <Table className="ActiveHomeTable HomeTable" bordered hover size="sm">
            <thead>
                <tr className="bg-primary">
                    <th></th>
                    <th>{ExceptionsLabels.table.headers.exceptionCode}</th>
                    <th>{ExceptionsLabels.table.headers.description}</th>
                </tr>
            </thead>
            <tbody>
                {allExceptionTypes.length > 0 ? (
                    allExceptionTypes.map((exceptionType, index) => (
                        <tr key={`exceptionRow-${index}`}>
                            <td>
                                <Checkbox
                                    onChange={onCheckboxChange}
                                    value={exceptionType.id}
                                    checked={selectedExpIds.some(a => a === exceptionType.id)}
                                />
                            </td>
                            <td>{exceptionType.code}</td>
                            <td className="tableExceptionDescriptionCell">{exceptionType.name}</td>
                        </tr>
                    ))
                ) : (
                    <tr>
                        <td colSpan={3}>{ExceptionsLabels.page.noData}</td>
                    </tr>
                )}
            </tbody>
        </Table>
    );
};