import { FC } from 'react';
import { Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { useSearchContext } from '../../../../contexts/SearchContext';
import { validateDate2 } from "../../../../helper/DateUtilities";
import { convertToDayjsValue } from "../../../../helper/DateUtilities";

const DateRangeSearch: FC = () => {
  const { 
    searchFormState: { control, formState: { errors } },
    searchState: { searchParams },
    searchStateActions: { setSearchParams },
  } = useSearchContext();

  return (
    <>
      <div className="row mb-3">
        <div className="col-sm-3 col-lg-2">
        </div>
        <div className="col-sm-8">
          <Controller
            control={control}
            name='byDateType'
            rules={{
              required: 'Please choose the Date Type for the search.',
            }}
            render={({ field }) => (
              <RadioGroup
                defaultValue="1"
                name="radio-buttons-group"
                onChange={field.onChange}
                value={field.value}
                row
              >
                <FormControlLabel value={1} control={<Radio />} label="Received Date" />
                <FormControlLabel value={2} control={<Radio />} label="Due Date" />
                <FormControlLabel value={3} control={<Radio />} label="Closed Date" />
              </RadioGroup>
            )}
          />
          <ErrorMessage
            errors={errors}
            name="byDateType"
            render={({ message }) => <span className="error-message">{message}</span>}
          />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-sm-3 col-lg-2 ps-lg-4 pt-lg-1">
          <label><strong>Search By Date Range</strong></label>
        </div>
        <div className="col-sm-3">
          <Controller
            control={control}
            name='byDateFrom'
            rules={{
              required: 'Please enter both From and To Dates for the search.',
              validate: (date) => validateDate2(
                date,
                "Date From",
                undefined,
                undefined,
                searchParams.byDateTo,
                'The Date From cannot be after Date To.'
              )
            }}
            render={({ field }) => (
              <DatePicker
                views={['year', 'month', 'day']}
                slotProps={{
                  textField: {
                    placeholder: "Enter Date From",
                    className: "form-control requiredDate"
                  }
                }}
                onChange={(date) => {
                  setSearchParams({
                    ...searchParams,
                    byDateFrom: date ? new Date(date.toDate()) : undefined
                  });
                  field.onChange(date);
                }}
                value={convertToDayjsValue(field.value)}
              />
            )}
          />
          <ErrorMessage
            errors={errors}
            name="byDateFrom"
            render={({ message }) => <span className="error-message">{message}</span>}
          />
        </div>
        <div className="col-sm-3">
          <Controller
            control={control}
            name='byDateTo'
            rules={{
              required: 'Please enter both From and To Dates for the search.',
              validate: (date) => validateDate2(
                date,
                "Date From",
                searchParams.byDateFrom,
                'The Date To cannot be earlier than Date From.',
                undefined,
                undefined
              )
            }}
            render={({ field }) => (
              <DatePicker
                views={['year', 'month', 'day']}
                slotProps={{
                  textField: {
                    placeholder: "Enter Date To",
                    className: "form-control requiredDate"
                  }
                }}
                onChange={(date) => {
                  setSearchParams({
                    ...searchParams,
                    byDateTo: date ? new Date(date.toDate()) : undefined
                  });
                  field.onChange(date);
                }}
                value={convertToDayjsValue(field.value)}
              />
            )}
          />
          <ErrorMessage
            errors={errors}
            name="byDateTo"
            render={({ message }) => <span className="error-message">{message}</span>}
          />
        </div>
      </div>
    </>
  );
};

export default DateRangeSearch;