import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { 
  SearchActionsType, 
  SearchStateActionsType, 
  SearchStateType, 
  SearchType 
} from '../../../types/SearchType';
import SearchParamModel from '../../../models/SearchParamModel';
import SearchWrapperModel from '../../../models/SearchWrapperModel';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useRequestService } from '../../../contexts/RequestContext';

export const useSearch = (): SearchType => {
  const location = useLocation();
  const { quSearchWrapper } = (location?.state ?? {}) as { quSearchWrapper?: SearchWrapperModel };
  const { previousPageUrls = [] } = (location?.state ?? {}) as { previousPageUrls: string[] };
  const [initiated,setInitiated]=useState<boolean>(true);

  const [btnDisable, setBtnDisable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState(quSearchWrapper?.rows ?? []);
  const [searchParams, setSearchParams] = useState<SearchParamModel>(
    quSearchWrapper?.searchParams ?? new SearchParamModel()
  );

  const requestService = useRequestService();
  const searchFormState = useForm<SearchParamModel>({ 
    shouldUnregister: false, 
    defaultValues: quSearchWrapper?.searchParams?? new SearchParamModel()
  });

  // Functions
  const onSearchSubmit: SubmitHandler<SearchParamModel> = async (data) => {
    setLoading(true); 
    setSearchParams(searchParams=>data);  

    const rqst =  await requestService.SearchBy(data);
    setRows(rqst);
    setLoading(false);  
  };

  // Effects
  useEffect(() => { 
    searchFormState.reset(searchParams); 
   }, []);

  useEffect(() => {       
    let params= new SearchParamModel(); 

    params.searchType= searchParams.searchType; 
    setSearchParams(params); 
    searchFormState.reset(searchParams)

    if(!initiated) setRows([]);
    
    setInitiated(false);
  }, [searchParams.searchType]);

  // Search State and Actions
  const searchStateType: SearchStateType = {
    btnDisable,
    loading,
    rows,
    searchParams, 
    previousPageUrls
  };

  const searchStateActions: SearchStateActionsType = {
    setBtnDisable,
    setLoading,
    setRows,
    setSearchParams
  };

  const searchActions: SearchActionsType = {
    navigate: useNavigate(),
    onSearchSubmit: onSearchSubmit,
  };
  
  return {
    searchState: searchStateType,
    searchStateActions: searchStateActions,
    searchFormState,
    searchActions: searchActions
  };
};
