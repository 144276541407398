import { Card, Row } from "react-bootstrap";
import React from "react";
import { useApplicantEditContext } from "../../../../contexts/ApplicantEditContext";
import ValidationAlert from "../../../../component/ValidationAlert";
import PhoneInput from "./PhoneInput";
import EmailInput from "./EmailInput";
import { ApplicantLabels } from "../../components/ApplicantLabels";

const ContactInfoEdit: React.FC = () => {
    const {
        applicantEditState: { errContactMsg },
    } = useApplicantEditContext();

    return (
        <Card className="mb-2 same-height">
            <Card.Header><strong>Contact Information</strong></Card.Header>
            <Card.Body>
                {errContactMsg && (
                    <Row className="mb-3">
                        <ValidationAlert message={errContactMsg} />
                    </Row>
                )}

                <PhoneInput 
                    controlName="phoneDay" 
                    label={ApplicantLabels.labels.dayPhone} 
                    placeholder="Enter Daytime Phone Number"
                />
                <PhoneInput 
                    controlName="phoneEvening" 
                    label={ApplicantLabels.labels.eveningPhone} 
                    placeholder="Enter Evening Phone Number"
                />
                <PhoneInput 
                    controlName="phoneCell" 
                    label={ApplicantLabels.labels.cellPhone} 
                    placeholder="Enter Cell Phone Number"
                />
                <PhoneInput 
                    controlName="phoneFax" 
                    label={ApplicantLabels.labels.fax} 
                    placeholder="Enter Fax Number"
                />
                <EmailInput />
            </Card.Body>
        </Card>
    );
};

export default ContactInfoEdit;