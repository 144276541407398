import { useEffect, useState } from "react";
import RequestModel from "../../../../models/RequestModel";
import RequestActionTypeModel from "../../../../models/RequestActionTypeModel";
import { useActionTypeService } from "../../../../contexts/ActionTypeContext";
import { useRequestService } from "../../../../contexts/RequestContext";

export const useRequestData = (id: string | undefined) => {
    const [request, setRequest] = useState<RequestModel>();
    const [allActionTypes, setAllActionTypes] = useState<RequestActionTypeModel[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [steps, setSteps] = useState<string[]>(['Initiated', 'Active', 'Closed']);
    
    const actionTypeService = useActionTypeService();
    const requestService = useRequestService();

    useEffect(() => {
        const fetchLookupData = async () => {
            setLoading(true);
            const actionTypes = await actionTypeService.GetAll();
            setAllActionTypes(actionTypes);
            setLoading(false);
        }
        fetchLookupData();
    }, []);

    useEffect(() => {
        if (id) {
            const fetchData = async () => {
                setLoading(true);
                const rqst = await requestService.GetById(parseInt(id));
                if (rqst) {
                    setRequest({ ...rqst });
                }
                setLoading(false);
            }
            fetchData();
        }
    }, [id]);

    useEffect(() => {
        if (request) {
            setSteps(['Initiated', (request.dueDate ? 'Active' : 'On Hold'), 'Closed']);
        }
    }, [request?.dueDate]);

    return { request, allActionTypes, loading, steps };
};