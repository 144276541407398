import React from "react";
import { Row, Col, Card, Form } from "react-bootstrap";
import { Checkbox, Grid } from "@mui/material";
import { Link } from "react-router-dom";
import { formatDateAs } from "../../../helper/DateUtilities";
import { DateFormat } from "../../../helper/Constants";
import { pageRouteUrls } from "../../../helper/PageRouteUrls";
import RequestModel from "../../../models/RequestModel";
import RequestActionTypeModel from "../../../models/RequestActionTypeModel";
import { RequestLabels } from "./RequestLabels";

interface ActionSummaryProps {
    request: RequestModel;
    allActionTypes: RequestActionTypeModel[];
    previousPageUrls: string[];
    mode: 'edit' | 'details';
    showNewAction?: boolean;
}

    const RequestActionSummary: React.FC<ActionSummaryProps> = ({
        request,
        allActionTypes,
        previousPageUrls,
        mode,
        showNewAction = true
    }) => {
        const renderActionRow = (index: number, type: RequestActionTypeModel) => {
            const action = request.requestActions?.find(a => a.requestActionTypeId === type.id);
            const isActionCompleted = !!action;

            return (
                <div className="row mb-3">
                    <div className="col-sm-2">
                        <Checkbox readOnly checked={isActionCompleted} />
                    </div>
                    <div className="col-sm-6">
                        {isActionCompleted ? (
                            <Link
                                to={pageRouteUrls.Action_Details(request.id, action.id)}
                                state={{ 
                                    previousPageUrls: [
                                        ...previousPageUrls, 
                                        mode === 'edit' 
                                            ? pageRouteUrls.Request_Edit(request.id)
                                            : pageRouteUrls.Request_Details(request.id)
                                    ] 
                                }}
                            >
                                {type?.name}
                            </Link>
                        ) : (
                            type?.name
                        )}
                    </div>
                    <div className="col-sm-4">
                        {action?.actionDate ? (
                            <Form.Control plaintext readOnly value={formatDateAs(action.actionDate, DateFormat)} />
                        ) : '--'}
                    </div>
                </div>
            );
        };

        const renderActionGrid = (start: number, end: number) => (
            <Grid container direction={'column'} rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
                {allActionTypes.slice(start, end).map((aType, index) => (
                    <Grid item xs={3} key={`requestActionRow${start + index}`}>
                        {renderActionRow(start + index, aType)}
                    </Grid>
                ))}
            </Grid>
        );

        return (
            <Card className="mb-3">
                <Card.Header>
                    <Row>
                        <Col className="fs-5">
                            <strong className="mx-2 colorDark">{RequestLabels.headers.actionSummary}</strong>
                        </Col>
                        {showNewAction && (
                            <Col className="textAlign-end">
                                <Link
                                    to={pageRouteUrls.Action_Add(request.id)}
                                    state={{ 
                                        previousPageUrls: [
                                            ...previousPageUrls, 
                                            mode === 'edit' 
                                                ? pageRouteUrls.Request_Edit(request.id)
                                                : pageRouteUrls.Request_Details(request.id)
                                        ] 
                                    }}
                                    className={`btn btn-success`}
                                >
                                    {RequestLabels.buttons.newAction}
                                </Link>
                            </Col>
                        )}
                    </Row>
                </Card.Header>
                <Card.Body>
                    <Row>
                        <Col>{renderActionGrid(0, 5)}</Col>
                        <Col>{renderActionGrid(5, 10)}</Col>
                        <Col>{renderActionGrid(10, 15)}</Col>
                        <Col>{renderActionGrid(15, allActionTypes.length)}</Col>
                    </Row>
                </Card.Body>
            </Card>
        );
    };

export default RequestActionSummary;