import { postcodeValidator } from "postcode-validator";
import { addressValidationExp, namesValidationExp } from "../../../../helper/ValidationUtilities";

const useAddressValidation = (isCountryCanada: () => boolean) => {
    return {
        getAddressValidation: (fieldName: string, maxLength: number, required: boolean) => ({
            pattern: { value: addressValidationExp, message: `Please enter a valid ${fieldName}` },
            required: required ? `${fieldName} is required` : false,
            maxLength: { value: maxLength, message: `${fieldName} cannot exceed ${maxLength} characters` }
        }),
        getPostalCodeValidation: () => ({
            pattern: { value: namesValidationExp, message: 'Please enter a valid Postal Code' },
            required: isCountryCanada() && 'Postal Code is required',
            maxLength: { value: (isCountryCanada() ? 7 : 20), message: "Please enter a valid Postal Code" },
            validate: (value: string) => {
                if (isCountryCanada()) {
                    return postcodeValidator(value, 'CA') || 'Please enter a valid Postal Code';
                }
                return true;
            }
        })
    };
};

export default useAddressValidation;