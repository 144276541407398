import React from "react";
import { Row, Col, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { pageRouteUrls } from "../../../helper/PageRouteUrls";
import { RequestLabels } from "./RequestLabels";

interface ApplicantDetailsProps {
    applicant: {
        id: number;
        firstName: string;
        lastName: string;
        contactDetails: string;
    };
    requestId: string | undefined;
    previousPageUrls: string[];
}

    const RequestApplicantDetails: React.FC<ApplicantDetailsProps> = ({ applicant, requestId, previousPageUrls }) => (
        <>
            <Row>
                <Col>
                    <Form.Label>{RequestLabels.labels.firstName}</Form.Label>
                    <Form.Control plaintext readOnly value={applicant.firstName} />
                </Col>
                <Col>
                    <Form.Label>{RequestLabels.labels.lastName}</Form.Label>
                    <Form.Control plaintext readOnly value={applicant.lastName} />
                </Col>
                <Col>
                    <Form.Label>{RequestLabels.labels.contactDetails}</Form.Label>
                    <Form.Control plaintext readOnly value={applicant.contactDetails} />
                </Col>
            </Row>
            <Row>
                <Col className="textAlign-end">
                    {applicant.id && (
                        <Link
                            to={pageRouteUrls.Applicant_Details(applicant.id)}
                            state={{
                                sourceRequestId: requestId,
                                previousPageUrls: previousPageUrls
                            }}
                        >
                            {RequestLabels.labels.moreDetails}
                        </Link>
                    )}
                </Col>
            </Row>
        </>
    );

export default RequestApplicantDetails;