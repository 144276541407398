import {createContext, useContext} from "react";
import {useHttpRequestService} from "./HttpRequestContext";
import {createApplicantService} from "./Bootstrap";
import {IApplicantService} from "../services/ApplicantService";

const ApplicantContext = createContext<IApplicantService | null>(null);

export const ApplicantProvider: React.FC<{children?: React.ReactNode}> =
    ({children}) => {
        const httpRequestService = useHttpRequestService();
        const applicantService = createApplicantService(httpRequestService);
        return (
            <ApplicantContext.Provider value={applicantService}>
                {children}
            </ApplicantContext.Provider>
        );
}

export const useApplicantService = () => {
    const applicantService = useContext(ApplicantContext);
    if (!applicantService) {
        throw new Error('useActionService must be used within a ActionProvider');
    }
    return applicantService;
};