export const ConfigureExceptionsLabels = {
    headers: {
        addException: "Add Exception",
        configureExceptions: "Configure Exceptions",
        exceptionTableHeaders: {
            selectUnselect: "Select/Unselect",
            exceptionCode: "Exception Code",
            description: "Description"
        }
    },

    labels: {
        exceptionCode: "Exception Code",
        description: "Description"
    },

    placeholders: {
        exceptionCode: "Enter Exception Code",
        description: "Enter Exception Description"
    },

    buttons: {
        save: "Save",
        cancel: "Cancel",
        delete: "Delete",
        add: "Add",
        yes: "Yes",
        no: "No"
    },

    messages: {
        noDataFound: "No Data found",
        deleteConfirmation: "Are you sure you want to delete the exception(s)?",
        attachedExceptionError: "One or more exceptions are attached to existing Requests and cannot be deleted.",
        duplicateCodeError: "The Exception code already exists. Please enter a different Exception code."
    },

    validation: {
        codeRequired: "Exception Code is required.",
        codeMaxLength: "Exception Code cannot exceed 50 characters.",
        descriptionRequired: "Exception Description is required.",
        descriptionMaxLength: "Exception Description cannot exceed 128 characters."
    }
};

export default ConfigureExceptionsLabels;