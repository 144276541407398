import React from "react";
import { Card, Row, Col, Container } from "react-bootstrap";
import RequestModel from "../../../../models/RequestModel";
import { pageRouteUrls } from "../../../../helper/PageRouteUrls";
import RequestApplicantDetails from "../../components/RequestApplicantDetails";
import { RequestLabels } from "../../components/RequestLabels";

interface ApplicantInfoProps {
    request: RequestModel;
    id: string | undefined;
    previousPageUrls: string[];
}

const RequestApplicantInfo: React.FC<ApplicantInfoProps> = ({
    request, 
    id, 
    previousPageUrls 
}) => {
    const detailsPreviousPageUrls = [
        ...previousPageUrls,
        pageRouteUrls.Request_Details(id)
    ];

    return (
        <Card className="mb-3">
            <Card.Header>
                <Row>
                    <Col className="fs-5">
                        <strong className="mx-2 colorDark">{RequestLabels.headers.applicant}</strong>
                    </Col>
                </Row>
            </Card.Header>
            <Card.Body>
                <Container>
                    <RequestApplicantDetails
                        applicant={request.applicant}
                        requestId={id}
                        previousPageUrls={detailsPreviousPageUrls}
                    />
                </Container>
            </Card.Body>
        </Card>
    );
};

export default RequestApplicantInfo;