import React from "react";
import { Card, Form } from "react-bootstrap";
import ApplicantModel from "../../../../models/ApplicantModel";
import { ApplicantLabels } from "../../components/ApplicantLabels";

const ContactInfoDetails: React.FC<{ applicant: ApplicantModel }> = ({ applicant }) => (
    <Card className="mb-2 same-height">
        <Card.Header><strong>{ApplicantLabels.headers.contactInformation}</strong></Card.Header>
        <Card.Body>
            <div className="row mb-3">
                <div className="col-sm-3">
                    <Form.Label>{ApplicantLabels.labels.dayPhone}</Form.Label>
                </div>
                <div className="col-sm-8">
                    <Form.Control plaintext readOnly value={applicant["phoneDay"]} />
                </div>
            </div>
            <div className="row mb-3">
                <div className="col-sm-3">
                    <Form.Label>{ApplicantLabels.labels.eveningPhone}</Form.Label>
                </div>
                <div className="col-sm-8">
                    <Form.Control plaintext readOnly value={applicant["phoneEvening"]} />
                </div>
            </div>
            <div className="row mb-3">
                <div className="col-sm-3">
                    <Form.Label>{ApplicantLabels.labels.cellPhone}</Form.Label>
                </div>
                <div className="col-sm-8">
                    <Form.Control plaintext readOnly value={applicant["phoneCell"]} />
                </div>
            </div>
            <div className="row mb-3">
                <div className="col-sm-3">
                    <Form.Label>{ApplicantLabels.labels.fax}</Form.Label>
                </div>
                <div className="col-sm-8">
                    <Form.Control plaintext readOnly value={applicant["phoneFax"]} />
                </div>
            </div>
            <div className="row mb-3">
                <div className="col-sm-3">
                    <Form.Label>{ApplicantLabels.labels.emailAddress}</Form.Label>
                </div>
                <div className="col-sm-8">
                    <Form.Control plaintext readOnly value={applicant["emailAddress"]} />
                </div>
            </div>
        </Card.Body>
    </Card>
);

export default ContactInfoDetails;
