import { FC } from 'react';
import { Card, Row, Col } from "react-bootstrap";
import { useSearchContext } from '../../../contexts/SearchContext';
import { LoadingSpinner } from "../../../component/LoadingSpinner";
import { useSearchColumns } from '../hooks/useSearchColumns';
import { useStripedDataGrid } from '../hooks/useStripedDataGrid';

const SearchResults: FC = () => {
  const { searchState: { loading, rows } } = useSearchContext();
  const columns = useSearchColumns();
  const StripedDataGrid = useStripedDataGrid();

  return (
    <Card className="mb-3">
      <Card.Header>
        <Row>
          <Col className="fs-5">
            <strong className="mx-2 colorDark">Search Results</strong>
          </Col>
        </Row>
      </Card.Header>
      <Card.Body>
        {loading ? (
          <LoadingSpinner />
        ) : (
          <div style={{ height: 371, width: '100%' }}>
            <StripedDataGrid
              rows={rows}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 5 },
                },
                sorting: {
                  sortModel: [{ field: 'fileNumber', sort: 'asc' }],
                },
              }}
              pageSizeOptions={[5, 10, 20]}
              getRowClassName={(params: any) =>
                params.indexRelativeToCurrentPage % 2 === 0 ? 'odd' : 'even'
              }
            />
          </div>
        )}
      </Card.Body>
    </Card>
  );
};

export default SearchResults;