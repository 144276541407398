import React from "react";
import {Form, Row, Col} from "react-bootstrap";
import {ErrorMessage} from "@hookform/error-message";
import {useActionEditContext} from "../../../../contexts/ActionEditContext";

const SearchReceivedEdit = () => {
    const actionEditContext = useActionEditContext();

    const {
        actionEditFormState: {register, formState: { errors} }
    } = actionEditContext;

    return (
        <Row className="mb-3">
            <Form.Group as={Col} lg="6">
                <Form.Label>Department Received Records From</Form.Label>
                <Form.Control
                    type="text" {...register("departmentReceivedRecordsFrom", {
                        required: 'Department Received Records From is required.',
                        maxLength: {value: 255, message: 'Please enter a valid Department Received Records From.'}
                    })}
                    placeholder="Enter the department received records from"
                    className="required"/>
                <ErrorMessage errors={errors} name="departmentReceivedRecordsFrom"
                              render={({message}) => <span
                                  className="error-message">{message}</span>}/>
            </Form.Group>
        </Row>
    );
};

export default SearchReceivedEdit;
