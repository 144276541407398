import React, { useMemo } from 'react';
import { Card } from 'react-bootstrap';
import { RequestFormSelect } from './RequestFormSelect';
import { useRequestEditContext } from '../../../../contexts/RequestEditContext';
import { 
    DisclosureTypeEnum, 
    DispositionTypeEnum,
    ReleaseStatusEnum,
    DeliveryMethodEnum,
    RequestTypeEnum 
} from '../../../../helper/Enums';
import { getEnumLabel } from '../../../../helper/Enums';
import { RequestLabels } from "../../components/RequestLabels";

export const RequestResponseEdit: React.FC = () => {
    const { 
        requestEditState: { requestTypeId },
        requestEditStateActions: { setDispositionTypeId },
        requestEditFormState: { register, formState }
    } = useRequestEditContext();

    let dispositionOptions;

    if (Number(requestTypeId) === Number(RequestTypeEnum.Correction)) {
        dispositionOptions = [
            DispositionTypeEnum.Corrected,
            DispositionTypeEnum.NotCorrected,
            DispositionTypeEnum.Internal$IncorrectRequests
        ].map(key => ({
            value: Number(key),
            label: getEnumLabel(DispositionTypeEnum[Number(key)])
        }));
    } else {
        dispositionOptions = Object.keys(DispositionTypeEnum)
            .filter((v) => !isNaN(Number(v)))
            .filter(item => ![DispositionTypeEnum.Corrected, DispositionTypeEnum.NotCorrected].includes(Number(item)))
            .map(key => ({
                value: Number(key),
                label: getEnumLabel(DispositionTypeEnum[Number(key)])
            }));
    }

    const disclosureOptions = Object.keys(DisclosureTypeEnum)
        .filter((v) => !isNaN(Number(v)))
        .map((key) => ({
            value: Number(key),
            label: getEnumLabel(DisclosureTypeEnum[Number(key)])
        }));

    const releaseStatusOptions = Object.keys(ReleaseStatusEnum)
        .filter((v) => !isNaN(Number(v)))
        .map((key) => ({
            value: Number(key),
            label: getEnumLabel(ReleaseStatusEnum[Number(key)])
        }));

    const deliveryMethodOptions = Object.keys(DeliveryMethodEnum)
        .filter((v) => !isNaN(Number(v)))
        .map((key) => ({
            value: Number(key),
            label: getEnumLabel(DeliveryMethodEnum[Number(key)])
        }));

    return (
        <Card className="mb-2">
            <Card.Header><strong>{RequestLabels.headers.responseDetails}</strong></Card.Header>
            <Card.Body>
                <RequestFormSelect
                    label={RequestLabels.labels.recordDisclosed}
                    name="disclosureTypeId"
                    register={register}
                    options={disclosureOptions}
                    formState={formState}
                />

                <RequestFormSelect
                    label={RequestLabels.labels.disposition}
                    name="dispositionTypeId"
                    register={register}
                    options={dispositionOptions}
                    onChange={(e) => setDispositionTypeId(e.target.value ? Number(e.target.value) : null)}
                    formState={formState}
                />

                <RequestFormSelect
                    label={RequestLabels.labels.releaseStatus}
                    name="releaseStatusTypeId"
                    register={register}
                    options={releaseStatusOptions}
                    formState={formState}
                />

                <RequestFormSelect
                    label={RequestLabels.labels.deliveryMethod}
                    name="deliveryMethodTypeId"
                    register={register}
                    options={deliveryMethodOptions}
                    formState={formState}
                />
            </Card.Body>
        </Card>
    );
};