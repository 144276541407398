import Layout from "../../../component/Layout"
import {Form} from "react-bootstrap";
import React, {useEffect} from "react";
import RequestModel from "../../../models/RequestModel";
import { SubmitHandler } from "react-hook-form";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import {LoadingSpinner} from "../../../component/LoadingSpinner";
import { BreadcrumbGenerator } from "../../../helper/BreadcrumbUtilities";
import useRequestEdit from "./hooks/useRequestEdit";
import RequestEditContext  from "../../../contexts/RequestEditContext";
import { RequestProgressEdit } from "./components/RequestProgressEdit";
import { RequestApplicantEdit } from "./components/RequestApplicantEdit";
import { RequestEdit } from "./components/RequestEdit";
import { RequestExceptions } from "../components/RequestExceptions";
import RequestActionSummary from "../components/RequestActionSummary";


export interface Props {
}

const FOIPRequestEdit: React.FC<Props> = props => {
    const requestEditContext = useRequestEdit();
    const {
        requestEditState: {previousPageUrls, id, request, loading, allActionTypes},
        requestEditFormState: {handleSubmit},
        requestEditActions: {performSubmit}
    } = requestEditContext;

    const breadcrumb = BreadcrumbGenerator.generateRequestBreadcrumb(previousPageUrls,id,true,null); 
    
    const onSubmit: SubmitHandler<RequestModel> = async (data) => {
        await performSubmit(data);
    };

    
    
    return (
        <Layout breadcrumList={breadcrumb}>
            { !loading && request ? 
            (
                <RequestEditContext.Provider value={requestEditContext}>
                <Form onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
                    
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <RequestProgressEdit />
                        <RequestApplicantEdit />

                        {  (request.applicantId && request.applicantId>0) as boolean? (
                            <RequestEdit />
                        ):(<></>)}

                        {request.id > 0 ?
                        (
                            <>
                                <RequestActionSummary request={request} allActionTypes={allActionTypes} previousPageUrls={previousPageUrls} mode="edit" />
                                <RequestExceptions requestExceptions={request?.requestExceptions ?? []} requestId={id} previousPageUrls={previousPageUrls} mode="edit" />
                            </>
                          ) : (
                            <></>
                        )
                    }
                    </LocalizationProvider>

                </Form>
                </RequestEditContext.Provider>
            ):
            (
                <LoadingSpinner />
            )
            }
        </Layout>
    )
}
export default FOIPRequestEdit 

