import {Container, Card, Row, Col} from "react-bootstrap";
import {Header} from "./Header";
import {Footer} from "./Footer";
import NavBarState from "../models/NavBarState";
import React, {useContext, useEffect, useState} from "react";
import ValidationAlert from "./ValidationAlert";
import {useApplicationContext} from "../contexts/ApplicationContext";

export interface LayoutProps {
    children?: any,
    breadcrumList?: any,
    pageTitle?: string,
    stateObj?: [NavBarState]
}

const Layout = ({
                    children,
                    breadcrumList,
                    pageTitle,
                    stateObj
                }: LayoutProps) => {
    //workaround so footer goes all the way to the bottom without background being all black on load
    document.body.className = "app-background";
    const { validationMessage, setValidationMessage } = useApplicationContext();

    useEffect(() => {
        return () => {
            setValidationMessage(null);
        };
    }, []);

    return (
        <>
            <Header breadcrumList={breadcrumList} stateObj={stateObj}></Header>

            <main id="main" role="main">
                {/* ADD A CONTAINER AND A TITLE WITH ICON OR SPECIFIC PAGES */}
                <Container className="mt-3">

                        <ValidationAlert message={validationMessage}/>
                        {pageTitle ? (
                            <>
                                <Row className="mb-3">
                                    <Col className="fs-5">
                                        <strong className="mx-2 colorDark">{pageTitle}</strong>
                                    </Col>
                                </Row>
                                <Card>{children}</Card>
                            </>
                        ) : (
                            children
                        )}
                </Container>
            </main>

            <Footer/>
        </>
    );
};

export default Layout;
