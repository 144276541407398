import React from "react";
import { Row, Col, Card, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { RequestExceptionModel } from "../../../models/RequestExceptionModel";
import { pageRouteUrls } from "../../../helper/PageRouteUrls";
import { RequestLabels } from "./RequestLabels";

interface RequestExceptionsTableProps {
    requestExceptions: RequestExceptionModel[];
    requestId: string | undefined;
    previousPageUrls: string[];
    mode: 'edit' | 'details';
    showManageButton?: boolean;
}

    export const RequestExceptions: React.FC<RequestExceptionsTableProps> = ({
        requestExceptions,
        requestId,
        previousPageUrls,
        mode,
        showManageButton = true
    }) => {

        const renderExceptionsTable = () => {
            return (
                <Table className="ActiveHomeTable HomeTable" bordered hover size="sm">
                    <thead>
                        <tr className="bg-primary">
                            <th>{RequestLabels.labels.exceptionCode}</th>
                            <th>{RequestLabels.labels.exceptionDescription}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {requestExceptions?.length > 0 ? (
                            requestExceptions.map((exception: RequestExceptionModel, index: number) => (
                                <tr key={`exception-${index}`}>
                                    <td>{exception.exceptionType?.code}</td>
                                    <td className="tableExceptionDescriptionCell">
                                        {exception.exceptionType?.name}
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan={2}>{RequestLabels.messages.noExceptions}</td>
                            </tr>
                        )}
                    </tbody>
                </Table>
            );
        };

        return (
            <Card className="mb-3">
                <Card.Header>
                    <Row>
                        <Col className="fs-5">
                            <strong className="mx-2 colorDark">{RequestLabels.headers.exceptions}</strong>
                        </Col>
                        {showManageButton && (
                            <Col className="textAlign-end">
                                <Link
                                    to={pageRouteUrls.MngExceptions(requestId)}
                                    state={{ previousPageUrls: [
                                        ...previousPageUrls, 
                                        mode === 'edit' 
                                            ? pageRouteUrls.Request_Edit(requestId)
                                            : pageRouteUrls.Request_Details(requestId)
                                    ]  }}
                                    className={`btn btn-success`}
                                >
                                    {RequestLabels.buttons.manageException}
                                </Link>
                            </Col>
                        )}
                    </Row>
                </Card.Header>
                <Card.Body>
                    {renderExceptionsTable()}
                </Card.Body>
            </Card>
        );
    };

