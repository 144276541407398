import {createContext, useContext} from "react";
import { RequestEditType } from "../types/RequestEditType";

const RequestEditContext = createContext<RequestEditType | undefined>(undefined);
export default RequestEditContext;

export const useRequestEditContext = () => {
    const requestEditContext = useContext(RequestEditContext);
    if (!requestEditContext) {
        throw new Error('useRequestEditContext must be used within a RequestEditContextProvider');
    }
    return requestEditContext;
};