import React from 'react';
import { Form } from 'react-bootstrap';
import { ErrorMessage } from '@hookform/error-message';
import { UseFormRegister } from 'react-hook-form';
import { RequestLabels } from "../../components/RequestLabels";

interface Option {
    value: number | string;
    label: string;
}

interface FormSelectProps {
    label: string;
    name: string;
    register: UseFormRegister<any>;
    options: Option[];
    rules?: object;
    onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
    className?: string;
    formState?: any;
    defaultValue?: string;
}

export const RequestFormSelect: React.FC<FormSelectProps> = ({
    label,
    name,
    register,
    options,
    rules,
    onChange,
    className = 'form-select',
    formState,
    defaultValue = ''
}) => {
    return (
        <div className="row mb-3">
            <div className="col-sm-4">
                <Form.Label>{label}</Form.Label>
            </div>
            <div className="col-sm-8">
                <select
                    {...register(name, rules)}
                    className={className}
                    onChange={onChange}
                    defaultValue={defaultValue}
                >
                    <option value="">{RequestLabels.placeholders.select}</option>
                    {options.map((option) => (
                        <option key={option.value} value={option.value}>
                            {option.label}
                        </option>
                    ))}
                </select>
                {formState && (
                    <ErrorMessage
                        errors={formState.errors}
                        name={name}
                        render={({ message }) => (
                            <span className="error-message">{message}</span>
                        )}
                    />
                )}
            </div>
        </div>
    );
};