import {Card, Col, Container, Row} from "react-bootstrap";
import Layout from "../component/Layout"
import {Link} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {pageRouteUrls} from "../helper/PageRouteUrls";
import {storageMng} from "../helper/StorageMng";
import {DashboardIconEnum} from "../helper/Enums";
import {FaPlus} from 'react-icons/fa';
import DashboardCard from '../component/dashboard/DashboardCard';
import DashboardRequestCard from '../component/dashboard/DashboardRequestCard';
import DashboardChart from '../component/dashboard/DashboardChart';
import RequestsSummaryModel from "../models/RequestsSummaryModel";
import {LoadingSpinner} from "../component/LoadingSpinner";
import {useRequestService} from "../contexts/RequestContext";

export interface Props {
}

const HomePage: React.FC<Props> = props => {

    const [requestsSummary, setRequestsSummary] = useState<RequestsSummaryModel>(new RequestsSummaryModel());
    const [loading, setLoading] = useState<boolean>(false);
    const userInfo = storageMng.getUserProfile();
    const requestService = useRequestService();

    const dashboardCardProperties = {
        totalRequest: {title: 'Total Request Received', fill: '#3b719f'},
        overdueRequest: {title: 'Overdue', fill: '#f16767'},
        openRequest: {title: 'Open', fill: '#9673a5'},
        completedRequest: {title: 'Completed', fill: '#197278'},
        carriedForwardRequest: {title: 'Carried Forward', fill: '#F8DE7E'},
        abandonedRequest: {title: 'Abandoned', fill: '#778899'},
        onTrackRequestDetail: {title: 'On Track', fill: 'green'},
        dueWeekRequestDetail: {title: 'Due Within 7 Days', fill: 'orange'},
        overdueRequestDetail: {title: 'Overdue', fill: 'red'},
        onHoldRequestDetail: {title: 'On Hold', fill: 'gray'}
    };
    const chartData = [
        {
            name: dashboardCardProperties.onTrackRequestDetail.title,
            value: requestsSummary.onTrackRequestsCount,
            fill: dashboardCardProperties.onTrackRequestDetail.fill
        },
        {
            name: dashboardCardProperties.dueWeekRequestDetail.title,
            value: requestsSummary.dueWeekRequestsCount,
            fill: dashboardCardProperties.dueWeekRequestDetail.fill
        },
        {
            name: dashboardCardProperties.overdueRequestDetail.title,
            value: requestsSummary.overdueRequestsCount,
            fill: dashboardCardProperties.overdueRequestDetail.fill
        },
        {
            name: dashboardCardProperties.onHoldRequestDetail.title,
            value: requestsSummary.onHoldRequestsCount,
            fill: dashboardCardProperties.onHoldRequestDetail.fill
        }
    ];

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            const rqsts = await requestService.GetRequestsSummary();
            if (rqsts) {
                setRequestsSummary(rqsts);
            }
            setLoading(false);
        }
        fetchData();
    }, []);

    return (
        <Layout>
            <Row>
                <Col md={5} className="mb-4 mb-lg-0">
                    <Card className="same-height">
                        <Card.Header>
                            <Row>
                                <Col className="fs-5">
                                    <strong className="mx-2 colorDark">Active Requests Summary</strong>
                                </Col>
                            </Row>
                        </Card.Header>
                        <Card.Body>
                            <Container>
                                {loading ? (
                                    <LoadingSpinner/>
                                ) : (
                                    <DashboardChart chartData={chartData}/>
                                )}
                            </Container>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={7}>
                    <Card className="same-height">
                        <Card.Header>
                            <Row>
                                <Col className="fs-5">
                                    <strong className="mx-2 colorDark">Total Requests Summary</strong>
                                </Col>
                                <Col className="textAlign-end">
                                    {userInfo?.isAdministrator ?
                                        (
                                            <Link to={pageRouteUrls.Request_Add()} className="btn btn-primary">
                                                <FaPlus className={'pb-1'}/> Add Request
                                            </Link>
                                        ) : (<></>)
                                    }
                                </Col>
                            </Row>
                        </Card.Header>
                        <Card.Body>
                            <Container>
                                {loading ? (
                                    <LoadingSpinner/>
                                ) : (<>
                                        <Row>
                                            <Col lg={6}>
                                                <DashboardCard iconType={DashboardIconEnum.Total}
                                                               backgroundColor={dashboardCardProperties.totalRequest.fill}
                                                               itemName={dashboardCardProperties.totalRequest.title}
                                                               itemsCount={requestsSummary.totalCount}/>
                                            </Col>
                                            <Col lg={6}>
                                                <DashboardCard iconType={DashboardIconEnum.Overdue}
                                                               backgroundColor={dashboardCardProperties.overdueRequest.fill}
                                                               itemName={dashboardCardProperties.overdueRequest.title}
                                                               itemsCount={requestsSummary.overdueCount}/>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg={6}>
                                                <DashboardCard iconType={DashboardIconEnum.Open}
                                                               backgroundColor={dashboardCardProperties.openRequest.fill}
                                                               itemName={dashboardCardProperties.openRequest.title}
                                                               itemsCount={requestsSummary.openCount}/>
                                            </Col>
                                            <Col lg={6}>
                                                <DashboardCard iconType={DashboardIconEnum.Completed}
                                                               backgroundColor={dashboardCardProperties.completedRequest.fill}
                                                               itemName={dashboardCardProperties.completedRequest.title}
                                                               itemsCount={requestsSummary.completedCount}/>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg={6}>
                                                <DashboardCard iconType={DashboardIconEnum.CarriedForward}
                                                               backgroundColor={dashboardCardProperties.carriedForwardRequest.fill}
                                                               itemName={dashboardCardProperties.carriedForwardRequest.title}
                                                               itemsCount={requestsSummary.carriedForwardCount}/>
                                            </Col>
                                            <Col lg={6}>
                                                <DashboardCard iconType={DashboardIconEnum.Abandoned}
                                                               backgroundColor={dashboardCardProperties.abandonedRequest.fill}
                                                               itemName={dashboardCardProperties.abandonedRequest.title}
                                                               itemsCount={requestsSummary.abandonedCount}/>
                                            </Col>
                                        </Row>
                                    </>
                                )}
                            </Container>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <br/>
            <Row>
                <Col>
                    <Card className="same-height">
                        <Card.Header>
                            <Row>
                                <Col className="fs-5">
                                    <strong className="mx-2 colorDark">Active Requests Details</strong>
                                </Col>
                            </Row>
                        </Card.Header>
                        <Card.Body>
                            <Container>
                                <Row>
                                    <Col md={3}>
                                        {loading ? (
                                            <LoadingSpinner/>
                                        ) : (
                                            <DashboardRequestCard
                                                cardTitle={dashboardCardProperties.onTrackRequestDetail.title}
                                                fill={dashboardCardProperties.onTrackRequestDetail.fill}
                                                rowName="onTrackRow"
                                                requests={requestsSummary.onTrackRequests}/>
                                        )}
                                    </Col>
                                    <Col md={3}>
                                        {loading ? (
                                            <LoadingSpinner/>
                                        ) : (
                                            <DashboardRequestCard
                                                cardTitle={dashboardCardProperties.dueWeekRequestDetail.title}
                                                fill={dashboardCardProperties.dueWeekRequestDetail.fill}
                                                rowName="dueWeekkRow"
                                                requests={requestsSummary.dueWeekRequests}/>
                                        )}
                                    </Col>
                                    <Col md={3}>
                                        {loading ? (
                                            <LoadingSpinner/>
                                        ) : (
                                            <DashboardRequestCard
                                                cardTitle={dashboardCardProperties.overdueRequestDetail.title}
                                                fill={dashboardCardProperties.overdueRequestDetail.fill}
                                                rowName="overdueRow"
                                                requests={requestsSummary.overdueRequests}/>
                                        )}
                                    </Col>
                                    <Col md={3}>
                                        {loading ? (
                                            <LoadingSpinner/>
                                        ) : (
                                            <DashboardRequestCard
                                                cardTitle={dashboardCardProperties.onHoldRequestDetail.title}
                                                fill={dashboardCardProperties.onHoldRequestDetail.fill}
                                                rowName="onHoldRow"
                                                requests={requestsSummary.onHoldRequests}/>
                                        )}
                                    </Col>
                                </Row>

                            </Container>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <br/>
        </Layout>
    )
}
export default HomePage