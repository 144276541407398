import React, {useContext} from "react";
import {Col, Form, Row} from "react-bootstrap";
import {ErrorMessage} from "@hookform/error-message";
import {blockInvalidNumberChar, blockInvalidWholeNumberChar} from "../../../../helper/ValidationUtilities";
import {useFormContext} from "react-hook-form";
import ActionEditContext, {useActionEditContext} from "../../../../contexts/ActionEditContext";

const ExtensionRequestedEdit = () => {
    const actionEditContext = useActionEditContext();

    const {
        actionEditFormState: {register, formState: { errors} }
    } = actionEditContext;

    return (
        <Row className="mb-3">
            <Form.Group as={Col} lg="6">
                <Form.Label>Extension Days</Form.Label>
                <Form.Control
                    type="number" {...register("extensionDays", {
                        required: 'Extension Days is required.',
                        min: {value: 0, message: 'Please enter a valid number of Extension Days.'},
                        max: {value: 30, message: 'Extension days cannot exceed a maximum limit of 30 days.'}
                    })}
                    onKeyDown={blockInvalidWholeNumberChar}
                    placeholder="Enter the extension days"
                    className="required"/>
                <ErrorMessage errors={errors} name="extensionDays"
                              render={({message}) => <span
                                  className="error-message">{message}</span>}/>
            </Form.Group>
        </Row>
    );
};

export default ExtensionRequestedEdit;
