import React from "react";
import { Row, Col, Card, Form, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Layout from "../../../component/Layout";
import { BreadcrumbGenerator } from "../../../helper/BreadcrumbUtilities";
import { LoadingSpinner } from "../../../component/LoadingSpinner";
import useApplicantEdit from "./hooks/useApplicantEdit";
import useSubmitApplicantEdit from "./hooks/useSubmitApplicantEdit";
import ContactInfoEdit from "./components/ContactInfoEdit";
import ApplicantEditContext from "../../../contexts/ApplicantEditContext";
import AddressInfoEdit from "./components/AddressInfoEdit";
import ApplicantInfoEdit from "./components/ApplicantInfoEdit";
import { ApplicantLabels } from "../components/ApplicantLabels";

const ApplicantEdit: React.FC = () => {
    const applicantEditContext = useApplicantEdit();
    const {
        applicantEditState: { id, sourceRequestId, btnDisable, previousPageUrls, loading },
        applicantEditFormState: { handleSubmit },
        applicantEditActions: { getCancelDestination }
    } = applicantEditContext;
    const { onSubmit } = useSubmitApplicantEdit(applicantEditContext);
    const breadcrumb = BreadcrumbGenerator.generateApplicantBreadcrumb(previousPageUrls, sourceRequestId, id, true);

    const ActionButtons = () => (
        <Col className="textAlign-end">
            <Button variant="primary" type="submit" disabled={btnDisable}>
                {ApplicantLabels.buttons.save}
            </Button>
            <Link
                to={getCancelDestination()}
                state={{ sourceRequestId: sourceRequestId ?? 0, previousPageUrls }}
                className="btn btn-dark ms-2"
            >
                {ApplicantLabels.buttons.cancel}
            </Link>
        </Col>
    );

    return (
        <Layout breadcrumList={breadcrumb}>
            <ApplicantEditContext.Provider value={applicantEditContext}>
                <Form onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Card className="mb-3">
                            <Card.Header>
                                <Row>
                                    <Col className="fs-5">
                                        <strong className="mx-2 colorDark">
                                            {id ? ApplicantLabels.editTitle : ApplicantLabels.addTitle}
                                        </strong>
                                    </Col>
                                    <ActionButtons />
                                </Row>
                            </Card.Header>
                            <Card.Body>
                                {loading ? (
                                    <LoadingSpinner />
                                ) : (
                                    <>
                                        <Row className="mb-3">
                                            <ApplicantInfoEdit />
                                        </Row>
                                        <Row className="mb-3">
                                            <Col>
                                                <AddressInfoEdit />
                                            </Col>
                                            <Col>
                                                <ContactInfoEdit />
                                            </Col>
                                        </Row>
                                    </>
                                )}
                            </Card.Body>
                        </Card>
                    </LocalizationProvider>
                </Form>
            </ApplicantEditContext.Provider>
        </Layout>
    );
}

export default ApplicantEdit;
