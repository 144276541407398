import {HttpRequestProvider} from "./HttpRequestContext";
import {NotificationConfigurationProvider} from "./NotificationConfigurationContext";
import React from "react";
import {ExceptionProvider} from "./ExceptionContext";
import {ActionTypeProvider} from "./ActionTypeContext";
import {ActionProvider} from "./ActionContext";
import {ApplicantProvider} from "./ApplicantContext";
import {RequestProvider} from "./RequestContext";
import {ApplicationContextProvider} from "./ApplicationContext";

const AppProviders: React.FC<{children?: React.ReactNode}> =
    ({children}) => {
        return (
            <ApplicationContextProvider>
            <HttpRequestProvider>
            <ApplicantProvider>
            <RequestProvider>
            <ActionProvider>
            <ActionTypeProvider>
            <NotificationConfigurationProvider>
            <ExceptionProvider>
                {children}
            </ExceptionProvider>
            </NotificationConfigurationProvider>
            </ActionTypeProvider>
            </ActionProvider>
            </RequestProvider>
            </ApplicantProvider>
            </HttpRequestProvider>
            </ApplicationContextProvider>
        );
};

export default AppProviders;