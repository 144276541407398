import { DataGrid, gridClasses } from '@mui/x-data-grid';
import { alpha, styled } from '@mui/material';

export const useStripedDataGrid = () => {
  return styled(DataGrid)(({ theme }) => ({
    [`& .${gridClasses.row}.even`]: {
      backgroundColor: alpha(
        theme.palette.primary.main,
        0.1 + theme.palette.action.selectedOpacity + theme.palette.action.hoverOpacity,
      ),
      '&:hover, &.Mui-hovered': {
        backgroundColor: theme.palette.grey[300],
        '@media (hover: none)': {
          backgroundColor: 'transparent',
        },
      },
      '&.Mui-selected': {
        backgroundColor: theme.palette.grey[400],
        '&:hover, &.Mui-hovered': {
          backgroundColor: theme.palette.grey[300],
          '@media (hover: none)': {
            backgroundColor: theme.palette.grey[400],
          },
        },
      },
    },

    [`& .${gridClasses.row}.odd`]: {
      '&:hover, &.Mui-hovered': {
        backgroundColor: theme.palette.grey[300],
        '@media (hover: none)': {
          backgroundColor: 'transparent',
        },
      },
      '&.Mui-selected': {
        backgroundColor: theme.palette.grey[400],
        '&:hover, &.Mui-hovered': {
          backgroundColor: theme.palette.grey[300],
          '@media (hover: none)': {
            backgroundColor: theme.palette.grey[400],
          },
        },
      },
    },
  }));
};