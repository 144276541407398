import {createContext, useContext} from "react";
import {INotificationConfigurationService} from "../services/NotificationConfigurationService";
import {useHttpRequestService} from "./HttpRequestContext";
import {createNotificationConfigurationService} from "./Bootstrap";

const NotificationConfigurationContext = createContext<INotificationConfigurationService | null>(null);

export const NotificationConfigurationProvider: React.FC<{children?: React.ReactNode}> =
    ({children}) => {
        const httpRequestService = useHttpRequestService();
        const notificationConfigurationService = createNotificationConfigurationService(httpRequestService);
        return (
            <NotificationConfigurationContext.Provider value={notificationConfigurationService}>
                {children}
            </NotificationConfigurationContext.Provider>
        );
}

export const useNotificationConfigurationService = () => {
    const notificationConfigurationService = useContext(NotificationConfigurationContext);
    if (!notificationConfigurationService) {
        throw new Error('useNotificationConfigurationService must be used within a NotificationConfigurationProvider');
    }
    return notificationConfigurationService;
};