import { useState } from 'react';
import { useForm, SubmitHandler } from "react-hook-form";
import ReportParamModel from '../../../models/ReportParamModel';
import { useRequestService } from '../../../contexts/RequestContext';

export const useReportGeneration = () => {
  const { control, register, handleSubmit, formState: { errors }, reset, getValues } = 
    useForm<ReportParamModel>({ defaultValues: new ReportParamModel() });
  
  const [btnDisable, setBtnDisable] = useState<boolean>(false);
  const requestService = useRequestService();

  const onSubmit: SubmitHandler<ReportParamModel> = async (data) => {
    setBtnDisable(true);
    const rqst = await requestService.GenerateReportBy(data);
    setBtnDisable(false);
  };

  return {
    control,
    register,
    handleSubmit,
    errors,
    reset,
    getValues,
    btnDisable,
    onSubmit
  };
};