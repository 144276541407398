import { Col, Row, Container, Card, Form, Spinner } from "react-bootstrap";
import { useRequestEditContext } from "../../../../contexts/RequestEditContext";
import { Link } from "react-router-dom";
import { DropdownList } from "react-widgets";
import { pageRouteUrls } from "../../../../helper/PageRouteUrls";
import { Alert } from "@mui/material";
import { blockLessGreaterThanChar } from "../../../../helper/ValidationUtilities";
import { useApplicantSearch } from '../hooks/useApplicantSearch';
import RequestApplicantDetails from "../../components/RequestApplicantDetails";
import { RequestLabels } from "../../components/RequestLabels";

export const RequestApplicantEdit: React.FC = () => {
    const {
        requestEditState: {
            id,
            previousPageUrls,
            request,
            searchLoading,
            applicantLoaded,
            showSearchDataNotFound,
            showNoApplicantAttachedAlert,
            suggestions
        },
        requestEditStateActions: actions,
        requestEditFormState: { setValue },
    } = useRequestEditContext();

    const {
        dropdownRef,
        handleApplicantSelect,
        handleSuggestionsFetch,
        resetSearchDDL
    } = useApplicantSearch({
        onApplicantSelect: actions.setRequest,
        setSearchLoading: actions.setSearchLoading,
        setSuggestions: actions.setSuggestions,
        setShowSearchDataNotFound: actions.setShowSearchDataNotFound,
        setShowNoApplicantAttachedAlert: actions.setShowNoApplicantAttachedAlert,
        setValue,
        currentRequest: request,
        applicantLoaded: applicantLoaded
    });

    const renderAlerts = () => (
        <>
            {showSearchDataNotFound && (
                <Alert severity="warning">
                    {RequestLabels.messages.noDataFound}
                </Alert>
            )}
            {showNoApplicantAttachedAlert && (
                <Alert severity="error">
                    {RequestLabels.messages.noApplicantFound}
                </Alert>
            )}
        </>
    );

    return (
        <Card className="mb-3">
            <Card.Header>
                <Row>
                    <Col className="fs-5">
                        <strong className="mx-2 colorDark">{RequestLabels.headers.applicant}</strong>
                    </Col>
                </Row>
            </Card.Header>
            <Card.Body>
                {searchLoading ? (
                    <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                ) : (
                    <Container>
                        <Row>
                            <Col className="mb-3">
                                <Form.Label>{RequestLabels.labels.searchApplicant}</Form.Label>
                                <div ref={dropdownRef}>
                                    <DropdownList
                                        id="searchDrplst"
                                        name="searchDrplst"
                                        placeholder={RequestLabels.placeholders.applicantName}
                                        data={suggestions}
                                        onSelect={value => handleApplicantSelect(value)}
                                        onBlur={value => resetSearchDDL()}
                                        onKeyDown={blockLessGreaterThanChar}
                                        dataKey='id'
                                        textField='firstName'
                                        filter={() => true}
                                        busy={searchLoading}
                                        busySpinner={<span className="fas fa-sync fa-spin" />}
                                        onSearch={(searchTerm) => handleSuggestionsFetch(searchTerm)}
                                        renderListItem={({ item }) => (
                                            <span>
                                                <strong>{item.firstName + ' ' + item.lastName}</strong>
                                                <span> | </span>
                                                <span>{item.contactDetails}</span>
                                            </span>
                                        )}
                                    />
                                </div>
                                {renderAlerts()}
                            </Col>
                            <Col className="textAlign-end">
                                <Link
                                    to={pageRouteUrls.Applicant_Add()}
                                    state={{
                                        sourceRequestId: id && Number.parseInt(id) ? id : 0,
                                        previousPageUrls: [...previousPageUrls, (id ? pageRouteUrls.Request_Edit(id) : pageRouteUrls.Request_Add())]
                                    }}
                                    className="btn btn-success"
                                >
                                    {RequestLabels.buttons.addApplicant}
                                </Link>
                            </Col>
                        </Row>
                        {
                            request?.applicantId && request?.applicantId > 0 
                            ? <RequestApplicantDetails
                                applicant={request.applicant}
                                requestId={id}
                                previousPageUrls={[...previousPageUrls, (id ? pageRouteUrls.Request_Edit(id) : pageRouteUrls.Request_Add())]} /> 
                            : <></>}
                    </Container>
                )}
            </Card.Body>
        </Card>
    );
};