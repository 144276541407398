import { Form, Row } from "react-bootstrap";
import { ErrorMessage } from "@hookform/error-message";
import { UseFormRegister, FieldValues, RegisterOptions, FieldErrors } from "react-hook-form";

interface FormFieldProps {
    name: string;
    label: string;
    register: UseFormRegister<any>;
    rules?: RegisterOptions;
    errors: FieldErrors;
    placeholder?: string;
    type?: string;
}

export const FormField: React.FC<FormFieldProps> = ({
    name,
    label,
    register,
    rules,
    errors,
    placeholder,
    type = "text"
}) => {
    return (
        <Row className="mb-3">
            <div className="col-sm-3 col-lg-2 ps-lg-4 pt-lg-1">
                <Form.Label>{label}</Form.Label>
            </div>
            <div className="col-sm-6">
                <Form.Control
                    type={type}
                    {...register(name, rules)}
                    placeholder={placeholder}
                />
                <ErrorMessage
                    errors={errors}
                    name={name}
                    render={({ message }) => (
                        <span className="error-message">{message}</span>
                    )}
                />
            </div>
        </Row>
    );
};