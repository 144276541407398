import { Controller } from "react-hook-form";
import { useApplicantEditContext } from "../../../../contexts/ApplicantEditContext";
import { Row, Form } from "react-bootstrap";
import InputMask from "react-input-mask";
import { ErrorMessage } from "@hookform/error-message";
import { ApplicantLabels } from "../../components/ApplicantLabels";

const EMPTY_MASKED_PHONE = '+1 (___) ___-____';
const VALID_PHONE_REGEX = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/gm;
const isValidPhoneNumber = (phone: string): boolean =>
    phone === EMPTY_MASKED_PHONE || !!(phone && new RegExp(VALID_PHONE_REGEX).test(phone));

const phoneValidation = {
    validate: (value: string) => 
        (value ? isValidPhoneNumber(value) : true) || 'Invalid Phone #'
} as const;


type PhoneInputProps = {
    controlName: 'phoneDay' | 'phoneEvening' | 'phoneCell' | 'phoneFax';
    label?: string;
    placeholder?: string;
};

const PhoneInput: React.FC<PhoneInputProps> = ({ 
    controlName, 
    label = ApplicantLabels.labels.dayPhone,
    placeholder = ApplicantLabels.placeholders.dayPhone
}) => {
    const {
        applicantEditFormState: { control, formState },
    } = useApplicantEditContext();
    
    return (
        <Row className="mb-3">
            <div className="col-sm-3">
                <Form.Label>{label}</Form.Label>
            </div>
            <div className="col-sm-8">
                <Controller
                    name={controlName}
                    control={control}
                    rules={phoneValidation}
                    render={({ field: { onChange, value } }) => (
                        <InputMask 
                            className="form-control"
                            mask='+1 (999) 999-9999'
                            placeholder={placeholder}
                            value={value}
                            onChange={onChange}
                            aria-label={label}
                        />
                    )}
                />
                <ErrorMessage 
                    errors={formState.errors} 
                    name={controlName} 
                    render={({ message }) => (
                        <span className="error-message">{message}</span>
                    )} 
                />
            </div>
        </Row>
    );
};

export default PhoneInput;
