import React from "react";
import { Form } from "react-bootstrap";
import { ErrorMessage } from "@hookform/error-message";
import { useApplicantEditContext } from "../../../../contexts/ApplicantEditContext";

export interface AddressFormInputProps {
    label: string;
    name: "address1" | "address2" | "city" | "province" | "country" | "postalCode";
    validation: object;
    placeholder: string;
    required?: boolean;
}

export const AddressFormInput: React.FC<AddressFormInputProps> = ({ label, name, validation, placeholder, required }) => {
    const { applicantEditFormState: { register, formState } } = useApplicantEditContext();
    
    return (
        <div className="row mb-3">
            <div className="col-sm-3">
                <Form.Label>{label}</Form.Label>
            </div>
            <div className="col-sm-8">
                <Form.Control 
                    type="text" 
                    {...register(name, validation)} 
                    className={required ? "required" : ''} 
                    placeholder={placeholder}
                />
                <ErrorMessage 
                    errors={formState.errors} 
                    name={name} 
                    render={({ message }) => <span className="error-message">{message}</span>}
                />
            </div>
        </div>
    );
}; 