import { createContext, useContext } from "react";
import { SearchType } from "../types/SearchType";

const SearchContext = createContext<SearchType | undefined>(undefined);
export default SearchContext;

export const useSearchContext = () => {
    const searchContext = useContext(SearchContext);
    if (!searchContext) {
        throw new Error('useSearchContext must be used within a SearchContextProvider');
    }
    return searchContext;
};