import { EmailExpValidator } from "../../../../helper/Constants";
import { useApplicantEditContext } from "../../../../contexts/ApplicantEditContext";
import { Row, Form } from "react-bootstrap";
import { ErrorMessage } from "@hookform/error-message";
import { ApplicantLabels } from "../../components/ApplicantLabels";


const emailValidation = {
    maxLength: { 
        value: 320, 
        message: "Please enter a valid email." 
    },
    pattern: {
        value: EmailExpValidator,
        message: "Please enter a valid email"
    }
} as const;

type EmailInputProps = {
    label?: string;
    placeholder?: string;
};

const EmailInput: React.FC<EmailInputProps> = ({ 
    label = ApplicantLabels.labels.emailAddress,
    placeholder = ApplicantLabels.placeholders.emailAddress
}) => {
    const {
        applicantEditFormState: { register, formState },
    } = useApplicantEditContext();

    return (
        <Row className="mb-3">
            <div className="col-sm-3">
                <Form.Label>{label}</Form.Label>
            </div>
            <div className="col-sm-8">
                <Form.Control 
                    type="email" 
                    {...register("emailAddress", emailValidation)}
                    placeholder={placeholder}
                    aria-label={label}
                />
                <ErrorMessage 
                    errors={formState.errors} 
                    name="emailAddress" 
                    render={({ message }) => (
                        <span className="error-message">{message}</span>
                    )} 
                />
            </div>
        </Row>
    );
};

export default EmailInput;