import {pageRouteUrls} from "./PageRouteUrls";

export const UserProfileLocalStorage = "UserProfileLocalStorage";

export const SignedOutSessionStorage = "SignedOutSessionStorage";

export const SignedOutRequestStatus = "SignedOut";

export const DateFormat = 'MM/dd/yyyy';

export const EmailExpValidator = /^[A-Z0-9._%+-]+@[A-Z0-9]([A-Z0-9.-]*[A-Z0-9])*\.[A-Z]{2,}$/i;

export const Breadcrumbs = {
        'search': [
            { title: 'Search' }
        ],
        'request-new': [
            { title: 'Search', link: pageRouteUrls.Search() },
            { title: 'New Request' }
        ],
        'request-details': [
            { title: 'Search', link: pageRouteUrls.Search() },
            { title: 'Request Details' }
        ],
        'request-edit': [
            { title: 'Search', link: pageRouteUrls.Search() },
            { title: 'Edit Request Details' }
        ],
        'applicant-details': [
            { title: 'Applicant Details' }
        ],
        'applicant-edit': [
            { title: 'Applicant Details' },
            { title: 'Edit Applicant' }
        ],
        'applicant-new': [
            { title: 'Add Applicant' }
        ],
        'action-new': [
            { title: 'New Action' }
        ],
        'action-details': [
            { title: 'Action Details' }
        ],
        'action-edit': [
            { title: 'Edit Action' }
        ],
        'manage-exceptions': [
            { title: 'Manage Exceptions' }
        ],
        'configure-exceptions': [
            { title: 'Configure Exceptions' }
        ],
        'configure-exceptions-new': [
            { title: 'Configure Exceptions', link: pageRouteUrls.ConfigureExceptions_View() },
            { title: 'Add Exception' }
        ],
        'configure-notifications': [
            { title: 'Configure Notifications' }
        ],
        'configure-notifications-edit': [
            { title: 'Configure Notifications', link: pageRouteUrls.NotificationsConfig_View() },
            { title: 'Edit Configure Notifications' }
        ]
}

