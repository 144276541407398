import { Button } from "react-bootstrap";
import { Col } from "react-bootstrap";
import { Row } from "react-bootstrap";
import { Card } from "react-bootstrap";
import Layout from "../../../component/Layout";
import { LoadingSpinner } from "../../../component/LoadingSpinner";
import { BreadcrumbGenerator } from "../../../helper/BreadcrumbUtilities";
import useExceptionView from "./hooks/useExceptionView";
import { Link } from "react-router-dom";
import { pageRouteUrls } from "../../../helper/PageRouteUrls";
import { ExceptionView } from "./components/ExceptionView";
import { ConfigureExceptionsLabels } from "../components/ConfigureExceptionsLabels";

export interface Props {
}

const ConfigureExceptionsView: React.FC<Props> = () => {
    const {
        loading,
        btnDisable,
        allExceptionTypes,
        selectedExpTypes,
        handleCheckboxChange,
        handleDelete
    } = useExceptionView();

    const breadcrumb = BreadcrumbGenerator.generateExceptionViewBreadcrumb();

    return (
        <Layout breadcrumList={breadcrumb}>
            {loading ? <LoadingSpinner/> : (
                <Card className="mb-3">
                    <Card.Header>
                        <Row>
                            <Col className="fs-5">
                                <strong className="mx-2 colorDark">
                                    {ConfigureExceptionsLabels.headers.configureExceptions}
                                </strong>
                            </Col>
                            <Col className="textAlign-end">
                                <Button 
                                    variant="danger" 
                                    onClick={handleDelete} 
                                    disabled={btnDisable || selectedExpTypes.length === 0}
                                >
                                    {ConfigureExceptionsLabels.buttons.delete}
                                </Button>
                                <Link to={pageRouteUrls.ConfigureExceptions_Add()} className="btn btn-primary">
                                    {ConfigureExceptionsLabels.buttons.add}
                                </Link>
                            </Col> 
                        </Row>
                    </Card.Header>
                    <Card.Body>
                        <ExceptionView 
                            exceptions={allExceptionTypes}
                            onCheckboxChange={handleCheckboxChange}
                        />
                    </Card.Body>
                </Card>
            )}
        </Layout>
    );
};

export default ConfigureExceptionsView;