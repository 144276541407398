import {createContext, useContext} from "react";
import { ExceptionAddType } from "../types/ExceptionAddType";

const ExceptionAddContext = createContext<ExceptionAddType | undefined>(undefined);
export default ExceptionAddContext;

export const useExceptionAddContext = () => {
    const exceptionAddContext = useContext(ExceptionAddContext);
    if (!exceptionAddContext) {
        throw new Error('useExceptionAddContext must be used within a ExceptionAddContextProvider');
    }
    return exceptionAddContext;
};  