import React, {createContext, useContext, useEffect, useState} from "react";
import Navbar from "react-bootstrap/Navbar";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faHome, faTasks, faSearch } from '@fortawesome/free-solid-svg-icons'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import {UserStatusButton} from "./azure/UserStatusButton";
import {pageRouteUrls} from "../helper/PageRouteUrls";
import {storageMng} from "../helper/StorageMng";
import UserModel from "../models/UserModel";
import NavBarState from "../models/NavBarState";
import {Link} from "react-router-dom";    

export interface NavigationProps {
    stateObj?:[NavBarState]
}
  
const Navigationbar  = ({stateObj}: NavigationProps) => {
    const [userInfo, setuserInfo] = useState<UserModel | null>(null);
    const faHomePropIcon = faHome as IconProp;
    const faTasksPropIcon = faTasks as IconProp;
    const faSearchPropIcon = faSearch as IconProp;
    
    const getUrlState=(url:string)=>{  
        return {quSearchWrapper:stateObj?.filter((a:NavBarState) => a.NavUrl ===url)[0].StateObj ?? undefined };
    } 

    useEffect(() => {
        const accountInfo = storageMng.getUserProfile();
        setuserInfo(accountInfo); 
    }, []);
  
    return (
        <Navbar expand="lg" className="navbar navbar-expand-lg navbar-light bg-light">
            <Container className="container-fluid"> 
                <Nav className="me-auto">
                    <Navbar.Toggle aria-controls="basic-navbar-nav" className="navbar-toggler"/>
                    <Navbar.Collapse className=" navbar-collapse">
                        <Nav className="navbar-nav me-auto mb-2 mb-lg-0">
                            <Nav.Item className="nav-item">
                                <Nav.Link className="nav-link active link-spinner ps-0" href="/home">
                                    <FontAwesomeIcon icon={faHomePropIcon} className="fa-lg" />
                                    <span className="menu-text">Home</span>
                                </Nav.Link>
                            </Nav.Item>
                            {userInfo?.isAdministrator ?
                                (
                                    <Nav.Item className="nav-item dropdown">
                                        <NavDropdown className=" active "
                                                     title={<><FontAwesomeIcon icon={faTasksPropIcon} className="fa-lg" /><span
                                                         className="menu-text">Management</span></>}> 
                                                <NavDropdown.Item className="dropdown-item link-spinner"
                                                                href={pageRouteUrls.Request_Add()}>Add Request</NavDropdown.Item>
                                                <NavDropdown.Item className="dropdown-item link-spinner"
                                                                href={pageRouteUrls.Applicant_Add()}>Add Applicant</NavDropdown.Item> 
                                                <NavDropdown.Item className="dropdown-item link-spinner"
                                                                href={pageRouteUrls.Reports()}>Reports</NavDropdown.Item>
                                                <NavDropdown.Item className="dropdown-item link-spinner"
                                                                href={pageRouteUrls.NotificationsConfig_View()}>Configure Notifications</NavDropdown.Item>
                                                <NavDropdown.Item className="dropdown-item link-spinner"
                                                                href={pageRouteUrls.ConfigureExceptions_View()}>Configure Exceptions</NavDropdown.Item>
                                        </NavDropdown>
                                    </Nav.Item>
                                ) : (<></>)
                            }
                            <Nav.Item className="nav-item">
                                <Link  className="nav-link active link-spinner" to={pageRouteUrls.Search()} 
                                    state={getUrlState(pageRouteUrls.Search())}>
                                    <FontAwesomeIcon icon={faSearchPropIcon} className="fa-lg" />
                                    <span className="menu-text">Search</span>
                                </Link >
                                {/* <Nav.Link state={getUrlState(pageRouteUrls.Search())} className="nav-link active link-spinner" href={pageRouteUrls.Search()} >
                                    <FontAwesomeIcon icon={faSearchPropIcon} className="fa-lg" />
                                    <span className="menu-text">Search</span>
                                </Nav.Link> */}
                            </Nav.Item>
                        </Nav>
                    </Navbar.Collapse>
                </Nav>
                <Navbar.Collapse className="collapse navbar-collapse justify-content-end">
                    <UserStatusButton/>
                </Navbar.Collapse>
            </Container>
        </Navbar>

    )
}
export default Navigationbar;

