import { Container } from "react-bootstrap"
import { config } from "../config"

export const Footer = () => {
    return (
        <footer id="footer">
            <div className="container-fluid">
                <Container>
                    <div className="row">
                        <div className="col-md-8">
                            <h4>Terms of Use and Privacy Statement</h4>
                            <p><a href="http://www.cbe.ab.ca/privacy-statement" title="Privacy Statement" target="_blank" className="privacy">CBE Privacy Statement</a></p>
                            <p className="cbe-text-muted">All activities in this web application are logged and monitored by system personnel. Individuals using this system without authority or in excess of their authority are subject to having all of their services revoked. Any illegal activity by the user or attack on CBE resources will be reported to local law enforcement and dealt with accordingly.</p>
                            <p className="cbe-text-muted">Version: {config.Version} - &copy;{new Date().getFullYear()} Calgary Board of Education. All rights reserved.</p>
                        </div>
                        <div className="col-md-4 text-md-end">
                            <h4>Get in Touch</h4>
                            <p>t | 403-817-7777</p>
                            <p><a href="https://ism.cbe.ab.ca" target="_blank">CBE Service Desk Portal</a> </p>
                        </div>
                    </div>
                </Container>

            </div>
        </footer>
    )
}