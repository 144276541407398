import {RequestActionModel} from "../../../models/RequestActionModel";
import React, {useEffect, useState} from "react";
import Layout from "../../../component/Layout";
import {pageRouteUrls} from "../../../helper/PageRouteUrls";
import {Link, useLocation, useNavigate, useParams} from "react-router-dom";
import {Row, Col, Card, Form, Container} from "react-bootstrap";
import {formatDateAs} from "../../../helper/DateUtilities";
import {DateFormat} from "../../../helper/Constants";
import PaymentReceivedDetails from "./PaymentReceivedDetails";
import SearchSentDetails from "./SearchSentDetails";
import SearchReceivedDetails from "./SearchReceivedDetails";
import PaymentRequestedDetails from "./PaymentRequestedDetails";
import ExtensionRequestedDetails from "./ExtensionRequestedDetails";
import ResponseReceivedDetails from "./ResponseReceivedDetails";
import {LoadingSpinner} from "../../../component/LoadingSpinner";
import {storageMng} from "../../../helper/StorageMng";
import {useActionService} from "../../../contexts/ActionContext";
import {BreadcrumbGenerator} from "../../../helper/BreadcrumbUtilities";

export interface Props {
}

const ActionDetails: React.FC<Props> = props => {
    const {id, requestId} = useParams();
    const location = useLocation();
    const { previousPageUrls } = (location?.state ? location.state : []) as { previousPageUrls: string[] };
    const userInfo = storageMng.getUserProfile();
    const [loading, setLoading] = useState<boolean>(false);
    const [actionData, setActionData] = useState<RequestActionModel>(new RequestActionModel());
    const actionService = useActionService();
    const breadcrumb = BreadcrumbGenerator.generateActionBreadcrumb(previousPageUrls, id, requestId, false);

    useEffect(() => {
        if (id) {
            const fetchData = async () => {
                const data = await actionService.GetById(parseInt(id));
                if(!data) return;
                setActionData(data!);
            }

            setLoading(true);
            fetchData().then(() => {
                setLoading(false);
            });
        }
    }, [id]);

    return (
        <Layout breadcrumList={breadcrumb}>
            {loading ? (
                <LoadingSpinner/>
            ) : (
                <Card>
                    <Card.Header>
                        <Row>
                            <Col className="fs-5">
                                <strong className="mx-2 colorDark">Action Details</strong>
                            </Col>
                            <Col className="textAlign-end">
                                {
                                    userInfo?.isAdministrator && actionData?.id   ?
                                        <Link to={pageRouteUrls.Action_Edit(requestId, id)} state={{previousPageUrls: previousPageUrls}} className="btn btn-primary">Edit</Link>
                                        : <></>
                                }
                                <Link to={ (previousPageUrls && previousPageUrls.length>0 && previousPageUrls[previousPageUrls.length - 1])? previousPageUrls[previousPageUrls.length - 1]: pageRouteUrls.Request_Details(requestId)} 
                                            state={{ previousPageUrls: previousPageUrls }}
                                            className="btn btn-dark">Cancel</Link>
                            </Col>
                        </Row>
                    </Card.Header>
                    <Card.Body>
                        <Container>
                        <Row className="mb-3">
                                <Form.Group as={Col} lg="6">
                                    <Form.Label>File Number</Form.Label>
                                    <span className="ps-4">{actionData.requestFileNumber} </span>
                                </Form.Group>

                                <Form.Group as={Col} lg="6"> 
                                </Form.Group>
                            </Row>
                            <Row className="mb-3">
                                <Form.Group as={Col} lg="6">
                                    <Form.Label>Action Type</Form.Label>
                                    <span className="ps-4">{actionData.actionType?.name} </span>
                                </Form.Group>

                                <Form.Group as={Col} lg="6">
                                    <Form.Label className={"pt-1 font-italic fw-bolder text-secondary"}>
                                        {actionData.actionType?.requestActionDescription}
                                    </Form.Label>
                                </Form.Group>
                            </Row>
                            <Row className="mb-3">
                                <Form.Group as={Col}>
                                    <Form.Label>Action Date</Form.Label>
                                    <span className="ps-4">{formatDateAs(actionData.actionDate, DateFormat)} </span>
                                </Form.Group>

                                <Form.Group as={Col}>
                                    <Form.Label>Bring Forward Due Date</Form.Label>
                                    <span className="ps-4">{formatDateAs(actionData.bringForwardDate, DateFormat)} </span>
                                </Form.Group>
                            </Row>
                            {
                                actionData.actionType?.requiresAmount ?
                                    <PaymentRequestedDetails actionData={actionData}/>
                                    : actionData.actionType?.requiresReceipt ?
                                        <PaymentReceivedDetails actionData={actionData}/>
                                        : actionData.actionType?.requiresDepartmentToSearch ?
                                            <SearchSentDetails actionData={actionData}/>
                                            : actionData.actionType?.requiresDepartmentReceivedRecordsFrom ?
                                                <SearchReceivedDetails actionData={actionData}/>
                                                : actionData.actionType?.requiresExtension ?
                                                    <ExtensionRequestedDetails actionData={actionData}/>
                                                    : actionData.actionType?.requiresResponseReceived ?
                                                        <ResponseReceivedDetails actionData={actionData} />
                                                        : <></>
                            }

                        </Container>
                    </Card.Body>
                </Card>
            )}
        </Layout>

    )
}
export default ActionDetails