import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useRequestService } from "../../../../contexts/RequestContext";
import { useApplicantService } from "../../../../contexts/ApplicantContext";
import { useActionTypeService } from "../../../../contexts/ActionTypeContext";
import RequestModel from "../../../../models/RequestModel";
import ApplicantModel from "../../../../models/ApplicantModel";
import RequestActionTypeModel from "../../../../models/RequestActionTypeModel";
import { RequestTypeEnum, DispositionTypeEnum } from "../../../../helper/Enums";
import { pageRouteUrls } from "../../../../helper/PageRouteUrls";
import { calculateLifecycle, calculateStepNumber } from "../../../../helper/DateUtilities";
import dayjs from "dayjs";
import { RequestEditType } from "../../../../types/RequestEditType";

function useRequestEdit(): RequestEditType {
    // State
    const { id } = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(false);
    const [searchLoading, setSearchLoading] = useState<boolean>(false);
    const [applicantLoaded, setApplicantLoaded] = useState<boolean>(false);
    const [request, setRequest] = useState<RequestModel | undefined>(undefined);
    const [btnDisable, setBtnDisable] = useState<boolean>(false);
    const [showSearchDataNotFound, setShowSearchDataNotFound] = useState<boolean>(false);
    const [showNoApplicantAttachedAlert, setShowNoApplicantAttachedAlert] = useState<boolean>(false);
    const [suggestions, setSuggestions] = useState<ApplicantModel[]>([]);
    const [requestTypeId, setRequestTypeId] = useState<RequestTypeEnum | null>(null);
    const [dispositionTypeId, setDispositionTypeId] = useState<DispositionTypeEnum | null>(null);
    const [steps, setSteps] = useState<string[]>(['Initiated', 'Active', 'Closed']);
    const [activeStep, setActiveStep] = useState(0);
    const [dueDateMinDate, setDueDateMinDate] = useState<dayjs.Dayjs>(dayjs(new Date()));
    const [isOnHold, setIsOnHold] = useState<boolean>(false);
    const [allActionTypes, setAllActionTypes] = useState<RequestActionTypeModel[]>([]);
    const [viewLifecycle, setViewLifecycle] = useState<number>(0);

    // Services
    const requestService = useRequestService();
    const applicantService = useApplicantService();
    const actionTypeService = useActionTypeService();

    // Form
    const requestEditFormState = useForm<RequestModel>({ 
        shouldUnregister: false, 
        defaultValues: {} 
    });
    const { reset } = requestEditFormState;

    // Extract state from location
    const { quApplicantId = 0 } = location?.state || {};
    const { previousPageUrls = [] } = location?.state || {};

    // Functions
    const handleSubmitRequest = async (data: RequestModel) => {
        try {
            setBtnDisable(true);
            
            if (!data.applicantId || data.applicantId <= 0) {
                setShowNoApplicantAttachedAlert(true);
                return;
            }

            setRequest(data);
            const savedRequest = await requestService.Save(data);
            
            if (!savedRequest) {
                throw new Error('Failed to save request');
            }

            const destination = id && parseInt(id) > 0
                ? pageRouteUrls.Request_Details(savedRequest.id)
                : pageRouteUrls.Home();

            navigate(destination, { 
                state: { previousPageUrls: previousPageUrls } 
            });
        } catch (error) {
            console.error('Error saving request:', error);
        } finally {
            setBtnDisable(false);
        }
    };

    const fetchActionTypes = async () => {
        const actionTypes = await actionTypeService.GetAll();
        setAllActionTypes(actionTypes);
    };

    const handleApplicantData = async (rqst: RequestModel) => {
        if ((quApplicantId ?? 0) > 0 && rqst.applicantId !== quApplicantId) {
            setApplicantLoaded(false);
            const applicantData = await applicantService.GetById(quApplicantId);
            if (applicantData) {
                rqst.applicantId = applicantData.id;
                rqst.applicant = applicantData;
            }
            setApplicantLoaded(true);
        }
    };

    const fetchRequest = async () => {
        if (!id) {
            const newRequest = new RequestModel();
            setRequest(newRequest);
            await handleApplicantData(newRequest);
            requestEditFormState.reset(newRequest);
            return;
        }

        const rqst = await requestService.GetById(parseInt(id));
        if (!rqst) return;

        await handleApplicantData(rqst);

        requestEditFormState.reset(rqst);
        setRequest(rqst);
        setIsOnHold(rqst.dueDate == undefined);
        setViewLifecycle(calculateLifecycle(rqst.dateReceived, rqst.completedDate));
    };

    const initializeData = async () => {
        setBtnDisable(true);
        setLoading(true);
        
        try {
            await fetchActionTypes();
            await fetchRequest();
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
            setBtnDisable(false);
        }
    };

    // Effects
    useEffect(() => {
        initializeData();
    }, [id, quApplicantId]);

    useEffect(() => {
        if (!request) return;

        setRequestTypeId(request.requestTypeId);
        setDispositionTypeId(request.dispositionTypeId);
        setDueDateMinDate(dayjs(request.dateReceived));  
        reset(request); 
    }, [request]);
    
    useEffect(() => {  
        setActiveStep(calculateStepNumber(request?.completedDate, request?.dateReceived)); 
    }, [viewLifecycle]);
     
    useEffect(() => {   
        if(!request) return;
        
        setSteps( ['Initiated',(request.dueDate? 'Active':'On Hold'),'Closed']);
    },[request?.dueDate] );  

    // State object
    const requestEditState = {
        id,
        request,
        loading,
        searchLoading,
        applicantLoaded,
        showSearchDataNotFound,
        showNoApplicantAttachedAlert,
        suggestions,
        requestTypeId,
        dispositionTypeId,
        steps,
        activeStep,
        dueDateMinDate,
        isOnHold,
        allActionTypes,
        viewLifecycle,
        btnDisable,
        previousPageUrls,
        quApplicantId
    };

    // State actions object
    const requestEditStateActions = {
        setLoading,
        setSearchLoading,
        setApplicantLoaded,
        setShowSearchDataNotFound,
        setShowNoApplicantAttachedAlert,
        setSuggestions,
        setRequestTypeId,
        setDispositionTypeId,
        setSteps,
        setActiveStep,
        setDueDateMinDate,
        setIsOnHold,
        setAllActionTypes,
        setViewLifecycle,
        setBtnDisable,
        setRequest
    };

    // Actions object
    const requestEditActions = {
        navigate,
        performSubmit: async (data: RequestModel) => {
            await handleSubmitRequest(data);
        }
    };

    return {
        requestEditState,
        requestEditStateActions,
        requestEditFormState,
        requestEditActions
    };
}

export default useRequestEdit;
