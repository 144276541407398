import axios from "axios";
import axiosRetry from 'axios-retry';
import {config} from '../config.js';
import authUtility from "./AuthUtilities";

const axiosInstance: any  = axios.create({ baseURL: config.baseUrl, timeout:Number.parseInt(config.timeout), });

axiosRetry(axiosInstance, {
    retries: Number.parseInt(config.retryCount), // number of retries
    retryDelay: (retryCount) => {
        console.log(`retry attempt: ${retryCount}`);
        return retryCount *  Number.parseInt(config.retryDelay); // time interval between retries
    } 
  });
  // Add a request interceptor
  axiosInstance.interceptors.request.use(
    function (config: any) {
      return authUtility.addAuthorizedHeader(config, true);
    },
      function (error:any ) {
      // Do something with request error 
      return Promise.reject(error);
    }
  );
  axiosInstance.interceptors.response.use(
    (response: any) => {
      if (response.status === 401) {
       return authUtility.handleLogout();
      }
      return response;
    },
    (err: any) => { 
         return Promise.reject(err); 
    }
  );

export default axiosInstance;

 