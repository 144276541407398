import { useRef, useCallback, useEffect } from 'react';
import { useApplicantService } from '../../../../contexts/ApplicantContext';
import ApplicantModel from '../../../../models/ApplicantModel';
import RequestModel from '../../../../models/RequestModel';
import { UseFormSetValue } from 'react-hook-form';

interface UseApplicantSearchProps {
    onApplicantSelect: (request: RequestModel) => void;
    setSearchLoading: (loading: boolean) => void;
    setSuggestions: (suggestions: ApplicantModel[]) => void;
    setShowSearchDataNotFound: (show: boolean) => void;
    setShowNoApplicantAttachedAlert: (show: boolean) => void;
    setValue: UseFormSetValue<RequestModel>;
    currentRequest?: RequestModel;
    applicantLoaded: boolean;
}

export const useApplicantSearch = ({
    onApplicantSelect,
    setSearchLoading,
    setSuggestions,
    setShowSearchDataNotFound,
    setShowNoApplicantAttachedAlert,
    setValue,
    currentRequest,
    applicantLoaded
}: UseApplicantSearchProps) => {
    const applicantService = useApplicantService();
    const dropdownRef = useRef<HTMLDivElement>(null);

    const updatePopupVisibility = useCallback((suggestions: ApplicantModel[]) => {
        if (dropdownRef.current) {
            const popupContainer = dropdownRef.current.querySelector('.rw-popup-container') as HTMLElement;
            if (popupContainer) {
                popupContainer.style.display = suggestions.length > 0 ? 'block' : 'none';
            }
        }
    }, []);

    const resetSearchDDL = useCallback(() => {
        setSuggestions([]);
        setShowSearchDataNotFound(false);
        
        if (dropdownRef.current) {
            const valueContainer = dropdownRef.current.querySelector('.rw-dropdown-list-value');
            if (valueContainer) {
                valueContainer.innerHTML = '';
            }
        }
    }, [setSuggestions, setShowSearchDataNotFound]);

    const handleApplicantSelect = async (applicant: ApplicantModel | undefined) => {
        setSearchLoading(true);
        try {
            if (applicant) {
                const updatedRequest = {
                    ...currentRequest ?? new RequestModel(),
                    applicantId: applicant.id,
                    applicant
                };
                
                setValue("applicantId", applicant.id);
                setValue("applicant", applicant);
                onApplicantSelect(updatedRequest);
                setShowNoApplicantAttachedAlert(false);
            }
        } finally {
            resetSearchDDL();
            setSearchLoading(false);
        }
    };

    const handleSuggestionsFetch = async (searchTerm: string) => {
        const results = await applicantService.Search(searchTerm);
        setSuggestions(results);
        updatePopupVisibility(results);
        setShowSearchDataNotFound(!results || results.length === 0);

        if (!searchTerm) {
            resetSearchDDL();
        }
    };

    useEffect(() => {
        const hasApplicant = currentRequest?.applicant?.id ?? 0 > 0;
        if (hasApplicant && applicantLoaded) {
            handleApplicantSelect(currentRequest?.applicant);   
        }
    }, [applicantLoaded, currentRequest?.applicant]);

    return {
        dropdownRef,
        handleApplicantSelect,
        handleSuggestionsFetch,
        resetSearchDDL
    };
};