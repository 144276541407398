import {createContext, useContext} from "react";
import {useHttpRequestService} from "./HttpRequestContext";
import {createRequestService} from "./Bootstrap";
import {IRequestService} from "../services/RequestService";

const RequestContext = createContext<IRequestService | null>(null);

export const RequestProvider: React.FC<{children?: React.ReactNode}> =
    ({children}) => {
        const httpRequestService = useHttpRequestService();
        const requestService = createRequestService(httpRequestService);
        return (
            <RequestContext.Provider value={requestService}>
                {children}
            </RequestContext.Provider>
        );
}

export const useRequestService = () => {
    const requestService = useContext(RequestContext);
    if (!requestService) {
        throw new Error('useRequestService must be used within a RequestProvider');
    }
    return requestService;
};