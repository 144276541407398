import ApplicantModel from "../models/ApplicantModel";
import {IHttpRequestService} from "./HttpRequestService";

export interface IApplicantService {
    GetById(id: number): Promise<ApplicantModel | undefined>;
    Save(data:ApplicantModel): Promise<ApplicantModel | undefined>;
    Search(keyword:string|undefined): Promise<ApplicantModel[]>;
}

export class ApplicantService implements IApplicantService {
    constructor(private  httpRequestService: IHttpRequestService) {}
     
    private Prepare(data:ApplicantModel) :ApplicantModel
    {
        if(data?.dateOfBirth)    
            data.dateOfBirth=new Date( data.dateOfBirth);
        return data;
    }


    public async GetById(id:number):Promise<ApplicantModel|undefined>{
        try{  
            if(id>0)
            {
                const { data } = await this.httpRequestService.Get(`/api/v1/Applicant/${id}`);
                return data? this.Prepare(data):data;
            }
            else
                return new ApplicantModel();
        }catch(e)
        {
            this.httpRequestService.HandleError(e);
        }
        return undefined;
    }

    public async Save(data:ApplicantModel):Promise<ApplicantModel|undefined>{
        try{  
    
            const response  =
            data.id>0 ?
                await this.httpRequestService.Put(`/api/v1/Applicant/Update`,data):
                await this.httpRequestService.Post(`/api/v1/Applicant/Add`,data);
            
            if(response)
                return response as ApplicantModel;
        }catch(e)
        {
            this.httpRequestService.HandleError(e);
        }
        return undefined;
    }

    public async Search(keyword:string|undefined):Promise<ApplicantModel[]>{
        keyword = keyword?.trim();

        try{
            if(keyword && keyword.length>=2)
            { 
                const maxRecords:number=10; 
                const url=`/api/v1/Applicant/search/${maxRecords}/${encodeURIComponent(keyword)}`;
                let { data } = await this.httpRequestService.Get(url);
                
                if(data && data.length>0)
                {
                    data.forEach((d: ApplicantModel) => {
                        d = this.Prepare(d);
                    }); 
                    
                    return data as ApplicantModel[]
                }
            }
        }catch(e)
        {
            console.log(e); //on bad request (i.e. invalid characters), just log it and return empty result
        }

        return [];
    } 
  };