import {createContext, useContext} from "react";
import {useHttpRequestService} from "./HttpRequestContext";
import {createExceptionService} from "./Bootstrap";
import {IExceptionService} from "../services/ExceptionService";

const ExceptionContext = createContext<IExceptionService | null>(null);

export const ExceptionProvider: React.FC<{children?: React.ReactNode}> =
    ({children}) => {
        const httpRequestService = useHttpRequestService();
        const exceptionService = createExceptionService(httpRequestService);
        return (
            <ExceptionContext.Provider value={exceptionService}>
                {children}
            </ExceptionContext.Provider>
        );
}

export const useExceptionService = () => {
    const exceptionService = useContext(ExceptionContext);
    if (!exceptionService) {
        throw new Error('useExceptionService must be used within a ExceptionProvider');
    }
    return exceptionService;
};