import {createContext, useContext} from "react";
import {ApplicantEditType} from "../types/ApplicantEditType";

const ApplicantEditContext = createContext<ApplicantEditType | undefined>(undefined);
export default ApplicantEditContext;

export const useApplicantEditContext = () => {
    const applicantEditContext = useContext(ApplicantEditContext);
    if (!applicantEditContext) {
        throw new Error('useApplicantEditContext must be used within a ApplicantEditContextProvider');
    }
    return applicantEditContext;
};
