import { DatePickerProps } from "@mui/x-date-pickers";
import { format } from "date-fns";
import dayjs, { Dayjs } from "dayjs";

// FUNCTION TO CONVERT JS DATE FORMAT TO YYYY-MM-DD FORMAT
export const getYYYYMMDD = (date: Date) => {
    if (!date) return;
    let month = date.getMonth() + 1;
    let day = date.getDate();
    return `${date.getFullYear()}-${month < 10 ? "0" + month : month}-${day < 10 ? "0" + day : day}`;
};

export const formatDateAs = (dateReceived: Date|undefined|null, dateFormat: string): string => {
    if (dateReceived)
        return format(dateReceived, dateFormat);
    else
        return "--";
}

export const validateDate = (date: Date|undefined|null, fieldName: string, minDate: Dayjs|null|undefined, minDateErrMsg: string|null|undefined, maxDate: Dayjs|null|undefined, maxDateErrMsg: string|null|undefined): any => {
    if (!date) return true;
    let formattedDate = dayjs(date);

    if (!formattedDate.isValid()) {
        return `Invalid Date. Please enter a valid ${fieldName}.`;
    }

    if (minDate && formattedDate.isBefore(minDate, 'day')) {
        console.log(minDate)
        return minDateErrMsg;
    }

    if (maxDate && formattedDate.isAfter(maxDate, 'day')) {
        return maxDateErrMsg;
    }
    return true;
};

export const validateDate2 = (date: Date|undefined|null, fieldName: string, dateMin: Date|null|undefined, minDateErrMsg: string|null|undefined, dateMax: Date|null|undefined, maxDateErrMsg: string|null|undefined): any => {
    if (!date) return true;

    let minDate = dateMin ? dayjs(dateMin) : undefined;
    let maxDate = dateMax ? dayjs(dateMax) : undefined;
    let formattedDate = dayjs(date);

    if (!formattedDate.isValid()) {
        return `Invalid Date. Please enter a valid ${fieldName}.`;
    }

    if (minDate && formattedDate.isBefore(minDate, 'day')) {
        return minDateErrMsg;
    }

    if (maxDate && formattedDate.isAfter(maxDate, 'day')) {
        return maxDateErrMsg;
    }
    return true;
};

export const calculateLifecycle = (date: Date|Dayjs|undefined|null, completedDate: Date|undefined|null): number => {
    if (!date) return 0;

    const today = completedDate ? new Date(completedDate.getFullYear(), completedDate.getMonth(), completedDate.getDate())
                         : new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());

    if (dayjs.isDayjs(date)) date = date.toDate();
    date = new Date(date.getFullYear(), date.getMonth(), date.getDate());

    const date2 = dayjs(today);
    const days = date2.diff(date, 'days');
    return days;
}

export const nullableDatesAreSame = (date1: Date|null|undefined, date2: Date|null|undefined): boolean => {
    return (!date1 && !date2) || dayjs(date1).isSame(date2, "day");
}

export const calculateStepNumber = (
  completedDate: Date | undefined | null,
  dateReceived: Date | undefined | null
): number => {
  if (completedDate) return 2;
  const lifecycle = calculateLifecycle(dateReceived, completedDate);
  return lifecycle < 1 ? 0 : 1;
};

export const getDatePickerProps = (trigger: (name: string) => void, fieldName: string): Partial<DatePickerProps<any>> => ({
  views: ['year', 'month', 'day'] as const,
  onError: (error: any) => error && trigger(fieldName),
}); 

export const convertToDayjsValue = (value: any) => {
    if (!value) return null;
    // If it's a plain object that might represent a date
    if (typeof value === 'object' && '$d' in value) {
        return dayjs(value.$d);
    }
    return dayjs(value);
};

