export const ApplicantLabels = {
    // Headers
    headers: {
        applicantDetails: "Applicant Details",
        addressInformation: "Address Information",
        contactInformation: "Contact Information"
    },

    // Labels
    labels: {
        firstName: "First Name",
        lastName: "Last Name",
        dateOfBirth: "Date of Birth",
        address1: "Address 1",
        address2: "Address 2",
        city: "City",
        province: "Province",
        country: "Country",
        postalCode: "Postal Code",
        dayPhone: "Day Phone #",
        eveningPhone: "Evening Phone #",
        cellPhone: "Cell Phone #",
        fax: "Fax #",
        emailAddress: "Email Address"
    },

    // Placeholders
    placeholders: {
        firstName: "Enter First Name",
        lastName: "Enter Last Name",
        dateOfBirth: "Enter Date of Birth",
        address1: "Enter Address 1",
        address2: "Enter Additional Address Information",
        city: "Enter City",
        province: "Enter Province Name",
        country: "Enter Country Name",
        postalCode: "Enter Postal or Zip Code",
        dayPhone: "Enter Daytime Phone Number",
        eveningPhone: "Enter Evening Phone Number",
        cellPhone: "Enter Cell Phone Number",
        fax: "Enter Fax Number",
        emailAddress: "Enter Email Address"
    },

    // Buttons
    buttons: {
        edit: "Edit",
        save: "Save",
        cancel: "Cancel",
        add: "Add"
    },

    // Page Titles
    editTitle: "Edit Applicant",
    addTitle: "Add Applicant"
};