import { useState, useEffect } from 'react';
import ExceptionTypeModel from '../../../../models/ExceptionTypeModel';
import { useExceptionService } from '../../../../contexts/ExceptionContext';
import Swal from 'sweetalert2';
import { ConfigureExceptionsLabels } from "../../components/ConfigureExceptionsLabels";

function useExceptionView() {
    const [loading, setLoading] = useState<boolean>(false);
    const [btnDisable, setBtnDisable] = useState<boolean>(false);
    const [allExceptionTypes, setAllExceptionTypes] = useState<ExceptionTypeModel[]>([]);
    const [selectedExpTypes, setSelectedExpTypes] = useState<number[]>([]);
    const exceptionService = useExceptionService();

    const fetchExceptions = async () => {
        setBtnDisable(true);
        setLoading(true);
        const exceptions = await exceptionService.GetExceptionTypes();
        setAllExceptionTypes(exceptions);
        setLoading(false);
        setBtnDisable(false);
    };

    useEffect(() => {
        fetchExceptions();
    }, []);

    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const checked = event.target.checked;
        const changedId = Number.parseInt(event.target.value);
        
        if (checked) { 
            setSelectedExpTypes(prev => [...prev, changedId]);
        } else {
            setSelectedExpTypes(prev => prev.filter(id => id !== changedId)); 
        }
    };

    const handleDelete = async () => {
        setBtnDisable(true);
        const attachedExceptions = await exceptionService.GetRequestExceptionsByExceptionTypeIds(selectedExpTypes);

        if (attachedExceptions?.length > 0) {
            Swal.fire({
                title: ConfigureExceptionsLabels.messages.attachedExceptionError,
                showCancelButton: false,
            });
        } else {
            const result = await Swal.fire({
                title: ConfigureExceptionsLabels.messages.deleteConfirmation,
                showCancelButton: true,
                confirmButtonText: ConfigureExceptionsLabels.buttons.yes,
                cancelButtonText: ConfigureExceptionsLabels.buttons.no,
            });

            if (result.isConfirmed) {
                setLoading(true);
                const success = await exceptionService.DeleteExceptionType(selectedExpTypes);
                if (success) {
                    await fetchExceptions();
                }
                setSelectedExpTypes([]);
                setLoading(false);
            }
        }
        setBtnDisable(false);
    };

    return {
        loading,
        btnDisable,
        allExceptionTypes,
        selectedExpTypes,
        handleCheckboxChange,
        handleDelete
    };
};

export default useExceptionView;