import { FC } from 'react';
import Layout from "../../component/Layout";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import SearchContext from '../../contexts/SearchContext';
import SearchResults from "./components/SearchResults";
import { BreadcrumbGenerator } from "../../helper/BreadcrumbUtilities";
import { pageRouteUrls } from "../../helper/PageRouteUrls";
import { useSearch } from './hooks/useSearch';
import SearchForm from './components/SearchForm';

const SearchPage: FC = () => {
  const searchContext = useSearch();
  const { searchParams, rows } = searchContext.searchState;
  const breadcrumb = BreadcrumbGenerator.generateSearchBreadcrumb();
  
  const persistenceState = () => ({ 
    searchParams,
    rows 
  });

  return (
    <SearchContext.Provider value={searchContext}>
      <Layout 
        breadcrumList={breadcrumb} 
        stateObj={[{ 
          NavUrl: pageRouteUrls.Search(), 
          StateObj: persistenceState() 
        }]}
      >
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <SearchForm />
          <SearchResults />
        </LocalizationProvider>
      </Layout>
    </SearchContext.Provider>
  );
};

export default SearchPage;