import {HttpRequestService, IHttpRequestService} from "../services/HttpRequestService";
import {NotificationConfigurationService} from "../services/NotificationConfigurationService";
import {ExceptionService} from "../services/ExceptionService";
import {ActionTypeService} from "../services/ActionTypeService";
import {ActionService} from "../services/ActionService";
import {ApplicantService} from "../services/ApplicantService";
import {RequestService} from "../services/RequestService";
import {ApplicationContextType} from "../types/ApplicationContextType";

export const createHttpRequestService = (applicationContext: ApplicationContextType) => { return new HttpRequestService(applicationContext); };
export const createNotificationConfigurationService = (httpRequestService: IHttpRequestService) => { return new NotificationConfigurationService(httpRequestService); };
export const createExceptionService = (httpRequestService: IHttpRequestService) => { return new ExceptionService(httpRequestService); };
export const createActionTypeService = (httpRequestService: IHttpRequestService) => { return new ActionTypeService(httpRequestService); };
export const createActionService = (httpRequestService: IHttpRequestService) => { return new ActionService(httpRequestService); };
export const createApplicantService = (httpRequestService: IHttpRequestService) => { return new ApplicantService(httpRequestService); };
export const createRequestService = (httpRequestService: IHttpRequestService) => { return new RequestService(httpRequestService); };